import React from 'react'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { ProductGroup } from 'schema-dts'
import { ProductPackageDetail } from '../../types/productPageV2'
import useBrand from '../../hooks/useBrand'
import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'
import { useProductDetails } from '../../hooks/useProductDetails'

interface Props {
  productPackageRows: ProductPackageDetail[]
}

const ProductGroupPageSEO: React.FC<Props> = ({ productPackageRows }) => {
  const brand = useBrand()
  const { urlPathBuilder, baseURL } = useUrlPathBuilder()

  const productSkus = useProductDetails(productPackageRows)

  return (
    <Helmet
      script={[
        helmetJsonLdProp<ProductGroup>({
          '@id': urlPathBuilder([], {
            baseURL,
            hash: 'productgroup',
          }),
          '@context': 'https://schema.org',
          '@type': 'ProductGroup',
          name: productSkus[0]?.plan_name,
          description: productSkus[0].sellingPoints.map(
            (sp) => sp.sellingPoint
          ),
          sku: productSkus[0]?.plan_code,
          brand: {
            '@type': 'Brand',
            name: brand.name,
          },
          offers: {
            '@type': 'Offer',
            priceCurrency: productSkus[0]?.currency_code,
            price: productSkus[0]?.amount,
            availability: 'https://schema.org/InStock',
            itemCondition: 'https://schema.org/NewCondition',
          },
          hasVariant: productSkus.slice(1).map((row) => ({
            '@type': 'Product',
            image:
              'https://images.ctfassets.net/7thvzrs93dvf/22o2uuUq2rTLLqC1cSQWrY/a469bcbadb58871e1fb0a392bc5fb443/EP-Package.png?w=1000&h=1338&q=90&fm=webp',
            name: row.plan_name,
            description: row.sellingPoints.map((sp) => sp.sellingPoint),
            offers: {
              '@type': 'Offer',
              priceCurrency: row?.currency_code,
              price: row?.amount,
              availability: 'https://schema.org/InStock',
              itemCondition: 'https://schema.org/NewCondition',
            },
          })),
        }),
      ]}
    />
  )
}

export default ProductGroupPageSEO
