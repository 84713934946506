import React from 'react'
import { Typography } from '@mui/material'
import Link from '../Link'
import { usePricePageStyles } from './styles'

interface PriceInsertProps {
  formattedAmount: string | undefined
  formattedDiscount?: string | null
  url?: string
  linkPriceClass: string
  originalPriceClass: string
}

export const ProductPriceInsert: React.FC<PriceInsertProps> = ({
  formattedAmount,
  formattedDiscount,
  url,
  linkPriceClass,
  originalPriceClass,
}) => {
  const { classes, cx } = usePricePageStyles()

  if (!formattedDiscount) {
    return (
      <Typography
        component={'span'}
        className={cx({
          [linkPriceClass]: true,
          [classes.linkPrice]: true,
        })}
      >
        <Link href={url}>{formattedAmount}</Link>
      </Typography>
    )
  }

  return (
    <>
      {formattedDiscount && (
        <Typography
          component={'span'}
          className={cx({
            [linkPriceClass]: true,
            [classes.linkPrice]: true,
          })}
        >
          <Link href={url}>{formattedDiscount}</Link>
        </Typography>
      )}
      <Typography
        component={'span'}
        className={cx({
          [originalPriceClass]: !!formattedDiscount,
          [classes.originalPrice]: true,
        })}
      >
        {formattedAmount}
      </Typography>
    </>
  )
}

export default ProductPriceInsert
