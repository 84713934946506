import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Carousel from '../LandingPage/Carousel'
import useBrand from '../../hooks/useBrand'

interface Props {
  children: React.ReactNode
}

const PricePackageCarousel: React.FC<Props> = ({ children }) => {
  const { classes, cx } = useStyles()
  const brand = useBrand()

  const responsiveSettings = [
    {
      breakpoint: 960,
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 2.2,
        infinite: false, // Disable infinite scrolling
        rtl: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 1.16,
        infinite: false, // Disable infinite scrolling
        rtl: false,
      },
    },
  ]

  return (
    <Box
      className={cx({
        [classes.carousel]: true,
        [classes.carouselZipJob]: brand.name === 'ZipJob',
      })}
    >
      <Carousel
        slidesToShow={3}
        arrowContainerClass={classes.arrowContainerSimple}
        arrowContainerLeftClass={
          brand.name !== 'ZipJob'
            ? classes.arrowContainerSimpleLeftTR
            : classes.arrowContainerSimpleLeft
        }
        dots={false}
        arrows={false}
        dotsClass={classes.breadcrumbs}
        autoplay={false}
        autoplaySpeed={4000}
        responsiveSettings={responsiveSettings}
      >
        {children}
      </Carousel>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  arrowContainerSimple: {
    '&&': {
      left: 'initial !important',
      right: '1rem !important',
      top: '-4rem',
      transform: 'translate(0)',
    },
  },
  arrowContainerSimpleLeft: {
    '&&': {
      marginRight: '4.75rem',
    },
  },
  arrowContainerSimpleLeftTR: {
    '&&': {
      marginRight: '3.75rem',
    },
  },
  breadcrumbs: {
    '&&': {
      [theme.breakpoints.down('md')]: {
        bottom: '-2em',
      },
    },
  },
  carousel: {
    '& [tabindex]': {
      '&:focus': {
        outline: 'none !important',
      },
    },
    width: '100%',
    overflow: 'hidden!important',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: '100vw',
    },
  },

  carouselZipJob: {
    marginTop: '1.5rem',
  },
}))

export default PricePackageCarousel
