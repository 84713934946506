import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { INLINES } from '@contentful/rich-text-types'
import { ProductPackagesSection as ProductPackagesSectionType } from '../../types/productPageV2'
import getColor, { getTextColor } from '../../utils/colors'
import formatContentfulClasses from '../../utils/customClasses'
import RichText from '../RichText'
import { BindToFields } from '../../types/contentfulAttributes'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import useHide from '../../hooks/useHide'
import ProductPackagesContainer from './ProductPackagesContainer'
import ProductGroupPageSEO from '../SEO/ProductGroupSEO'

export interface ProductPackagesSectionProps {
  packagesSection: ProductPackagesSectionType
  sectionClass?: string
}

const ProductPackagesSection: React.FC<ProductPackagesSectionProps> = ({
  packagesSection,
  sectionClass,
}) => {
  const { classes, cx } = useStyles(packagesSection)
  const [open, setOpen] = React.useState(false)
  const handleClick = () => setOpen(!open)
  const applyContentfulStyles = createContentfulClassGetter(packagesSection.css)
  const hideClasses = useHide(packagesSection.hideOn)

  // We show a different layout when we view the hero section that contains packages
  if (packagesSection.codeClasses?.includes('three-package-hero')) {
    return (
      <>
        <ProductGroupPageSEO
          productPackageRows={packagesSection.productPackageRows}
        />
        <Box
          className={cx(
            classes.box,
            sectionClass,
            classes.heroContainer,
            ...formatContentfulClasses(packagesSection.codeClasses),
            hideClasses
          )}
        >
          {packagesSection.sectionText && (
            <Box
              className={cx(
                classes.textBox,
                applyContentfulStyles(
                  BindToFields.SectionTextProductPackagesSection
                )
              )}
            >
              <RichText
                cssEntries={packagesSection.css}
                rawBody={packagesSection.sectionText.raw}
                references={packagesSection.sectionText.references}
              />
            </Box>
          )}
        </Box>
        <Box
          className={cx(
            classes.box,
            sectionClass,
            classes.cardsContainer,
            ...formatContentfulClasses(packagesSection.codeClasses),
            hideClasses
          )}
        >
          {packagesSection.productPackageRows && (
            <Box className={cx(classes.cardsBox, classes.liftUp)}>
              <ProductPackagesContainer
                productPackageRows={packagesSection.productPackageRows}
                css={packagesSection.css}
              />
              {packagesSection.cta && (
                <Box
                  className={cx(
                    classes.ctaContainer,
                    applyContentfulStyles(BindToFields.WrapperCTA)
                  )}
                >
                  <RichText
                    cssEntries={packagesSection.css}
                    rawBody={packagesSection.cta.raw}
                    references={packagesSection.cta.references}
                    options={{
                      props: {
                        [INLINES.ENTRY_HYPERLINK]: {
                          className: classes.cta,
                          open,
                          handleClick,
                        },
                        [BLOCKS.HEADING_1]: {
                          className: applyContentfulStyles(
                            BindToFields.RichTextH1
                          ),
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      <ProductGroupPageSEO
        productPackageRows={packagesSection.productPackageRows}
      />
      <Box
        className={cx(
          classes.box,
          sectionClass,
          ...formatContentfulClasses(packagesSection.codeClasses),
          hideClasses
        )}
      >
        {packagesSection.sectionText && (
          <Box
            className={cx(
              classes.textBox,
              applyContentfulStyles(
                BindToFields.SectionTextProductPackagesSection
              )
            )}
          >
            <RichText
              cssEntries={packagesSection.css}
              rawBody={packagesSection.sectionText.raw}
              references={packagesSection.sectionText.references}
            />
          </Box>
        )}
        {packagesSection.productPackageRows && (
          <Box className={classes.cardsBox}>
            <ProductPackagesContainer
              productPackageRows={packagesSection.productPackageRows}
              css={packagesSection.css}
            />
            {packagesSection.cta && (
              <Box
                className={cx(
                  classes.ctaContainer,
                  applyContentfulStyles(BindToFields.WrapperCTA)
                )}
              >
                <RichText
                  cssEntries={packagesSection.css}
                  rawBody={packagesSection.cta.raw}
                  references={packagesSection.cta.references}
                  options={{
                    props: {
                      [INLINES.ENTRY_HYPERLINK]: {
                        className: classes.cta,
                        open,
                        handleClick,
                      },
                      [BLOCKS.HEADING_1]: {
                        className: applyContentfulStyles(
                          BindToFields.RichTextH1
                        ),
                      },
                      [BLOCKS.PARAGRAPH]: {
                        className: applyContentfulStyles(
                          BindToFields.ParagraphCTA
                        ),
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

const useStyles = makeStyles<{ backgroundColor: string }>()(
  (theme, { backgroundColor }) => {
    const bgColor = getColor(backgroundColor, theme)
    const textColor = getTextColor(backgroundColor, theme)

    return {
      box: {
        alignItems: 'center',
        backgroundColor: bgColor,
        color: textColor,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '4rem',
        paddingTop: '4rem',
      },
      heroContainer: {
        [theme.breakpoints.up('md')]: {
          paddingBottom: '8rem',
        },
      },
      cardsContainer: {
        '&&': {
          background: theme.palette.background.default,
          paddingTop: '2rem',

          [theme.breakpoints.up('md')]: {
            paddingTop: 0,
          },
        },
      },
      cardsBox: {
        width: '100%',
      },
      liftUp: {
        [theme.breakpoints.up('md')]: {
          marginTop: '-7rem',
        },
      },
      textBox: {
        marginBottom: '2.625rem',
        maxWidth: '72%',
        textAlign: 'center',

        [theme.breakpoints.down(1024)]: {
          maxWidth: '84%',
        },

        [theme.breakpoints.down(769)]: {
          maxWidth: '100%',
          marginBottom: '2em',
          '& h2': {
            fontSize: '1.81em',
          },
          '& h1': {
            fontSize: '1.94em',
          },
        },
      },
      cta: {
        textDecoration: 'underline',
        display: 'inline',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      ctaContainer: {
        margin: '3rem auto 0',
        borderRadius: '6px',
        background: 'rgba(255, 255, 255, 0.15)',
        textAlign: 'center',
        padding: '0.3125rem',
        maxWidth: '25.375rem',

        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      },
    }
  }
)

export default ProductPackagesSection
