import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  HorizontalSplit2ColumnSection as HorizontalSplit2ColumnSectionType,
  AlignmentVariants,
} from '../../types/heroTextSection'
import getColor, { getTextColor } from '../../utils/colors'
import RichText from '../RichText'
import { BLOCKS } from '@contentful/rich-text-types'
import formatContentfulClasses from '../../utils/customClasses'
import useHide from '../../hooks/useHide'

export interface HorizontalSplit2ColumnSectionProps {
  section: HorizontalSplit2ColumnSectionType
  sectionClass?: string
}

const HorizontalSplit2ColumnSection: React.FC<
  HorizontalSplit2ColumnSectionProps
> = ({ section, sectionClass }) => {
  const { classes, cx } = useStyles({ section })
  const hideClasses = useHide(section.hideOn)

  return (
    <Box className={cx(classes.section, hideClasses)}>
      <Box
        className={cx(
          sectionClass,
          classes.backgroundTop,
          classes.boxTop,
          ...formatContentfulClasses(section.codeClasses)
        )}
      >
        <Box
          className={cx(classes.richTextContainer, {
            [classes.alignmentLeft]:
              section.column1TopContentAlignment === AlignmentVariants.Left,
            [classes.alignmentCenter]:
              section.column1TopContentAlignment === AlignmentVariants.Center,
            [classes.alignmentRight]:
              section.column1TopContentAlignment === AlignmentVariants.Right,
          })}
        >
          <RichText
            rawBody={section.column1TopContent.raw}
            references={section.column1TopContent.references}
            options={{
              props: {
                [BLOCKS.PARAGRAPH]: {
                  className: classes.buttonContainer,
                },
              },
            }}
          />
          <Box className={classes.column2Mobile}>
            <RichText
              rawBody={section.column2Content.raw}
              references={section.column2Content.references}
            />
          </Box>
        </Box>
      </Box>
      <Box
        className={cx(
          sectionClass,
          classes.backgroundBottom,
          classes.boxBottom
        )}
      >
        <Box
          className={cx({
            [classes.richTextContainer]: true,
            [classes.alignmentLeft]:
              section.column1TopContentAlignment === AlignmentVariants.Left,
            [classes.alignmentCenter]:
              section.column1TopContentAlignment === AlignmentVariants.Center,
            [classes.alignmentRight]:
              section.column1TopContentAlignment === AlignmentVariants.Right,
          })}
        >
          <RichText
            rawBody={section.column1BottomContent.raw}
            references={section.column1BottomContent.references}
          />
        </Box>
      </Box>
      <Box className={classes.column2}>
        <RichText
          rawBody={section.column2Content.raw}
          references={section.column2Content.references}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles<{ section: HorizontalSplit2ColumnSectionType }>()(
  (theme, { section }) => {
    const backgroundTopBgColor = getColor(section.backgroundColorTop, theme)
    const backgroundTopTextColor = getTextColor(
      section.backgroundColorTop,
      theme
    )

    const backgroundBottomBgColor = getColor(
      section.backgroundColorBottom,
      theme
    )
    const backgroundBottomTextColor = getTextColor(
      section.backgroundColorBottom,
      theme
    )

    return {
      alignmentCenter: {
        textAlign: 'center',

        '& button': {
          margin: '.25em .5em',
        },
        '& a[role=button]': {
          margin: '.25em .5em',
        },
      },
      alignmentLeft: {
        textAlign: 'left',
      },
      alignmentRight: {
        textAlign: 'right',
      },
      backgroundTop: {
        backgroundColor: backgroundTopBgColor,
        color: backgroundTopTextColor,
      },
      backgroundBottom: {
        backgroundColor: backgroundBottomBgColor,
        color: backgroundBottomTextColor,
      },
      boxBottom: {
        paddingBottom: '2em',
        paddingTop: '2.09em',
        [theme.breakpoints.down('md')]: {
          paddingBottom: '1em',
        },
      },
      boxTop: {
        paddingBottom: '3.46em',
        paddingTop: '6.1em',
        [theme.breakpoints.down('md')]: {
          paddingBottom: '3.48em',
          paddingTop: '2.2em',

          '& button': {
            margin: '.25em 0',
            width: '100%',
          },
          '& a[role=button]': {
            margin: '.25em 0',
            width: '100%',
          },
        },
      },
      column2: {
        height: '100%',
        maxHeight: '42.5em',
        padding: '3.01em 6% 1em 0',
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translate(0, -50%)',
        width: '48.3%',

        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
        '@media (min-width: 1456px)': {
          padding: '3.01em calc((100% - 91rem)/2 + 5.5rem) 1em 0)',
        },
      },
      column2Mobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
          display: 'block',
          marginTop: '2.98em',
        },
      },
      richTextContainer: {
        width: '40.5%',

        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
      section: {
        position: 'relative',
      },
      buttonContainer: {
        '& > a:nth-child(n)': {
          margin: '1rem 0',
          [theme.breakpoints.down('md')]: {
            margin: '1rem 0',
          },
        },
        '& > a:nth-child(2)': {
          marginLeft: '1rem',
          [theme.breakpoints.down('md')]: {
            margin: '1rem 0',
          },
        },
        '& > a': {
          [theme.breakpoints.down('md')]: {
            marginLeft: '0',
            width: '100%',
          },
        },
      },
    }
  }
)

export default HorizontalSplit2ColumnSection
