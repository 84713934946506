import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import Carousel from '../LandingPage/Carousel'
import useBrand from '../../hooks/useBrand'

const bounceInRight = keyframes({
  '0%, 60%, 75%, 90%, 100%': {
    transform: 'translateX(0)',
  },
  '30%': {
    transform: 'translateX(-10px)',
  },
  '45%': {
    transform: 'translateX(5px)',
  },
  '60%': {
    transform: 'translateX(-5px)',
  },
  '75%': {
    transform: 'translateX(2px)',
  },
})

interface Props {
  children: React.ReactNode
}

const PricePackageCarousel: React.FC<Props> = ({ children }) => {
  const { classes, cx } = useStyles()
  const brand = useBrand()

  const responsiveSettings = [
    {
      breakpoint: 960,
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 2.2,
        infinite: false, // Disable infinite scrolling
        rtl: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 1.2,
        infinite: false, // Disable infinite scrolling
        rtl: false,
      },
    },
  ]

  return (
    <Box
      className={cx({
        [classes.carousel]: true,
        [classes.carouselZipJob]: brand.name === 'ZipJob',
        [classes.arrowHideOnLast]: true, // Apply the new class here
      })}
    >
      <Carousel
        slidesToShow={3}
        arrowContainerClass={classes.arrowContainerSimple}
        arrowContainerLeftClass={
          brand.name !== 'ZipJob'
            ? classes.arrowContainerSimpleLeftTR
            : classes.arrowContainerSimpleLeft
        }
        dots={false}
        arrows={false}
        dotsClass={classes.breadcrumbs}
        autoplay={false}
        autoplaySpeed={4000}
        responsiveSettings={responsiveSettings}
      >
        {children}
      </Carousel>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  arrowContainerSimple: {
    '&:hover, &:active': {
      background:
        'linear-gradient(to right, rgba(255, 0, 0, 0), #ffffff)!important',
      border: 'none!important',
    },
    [theme.breakpoints.down('sm')]: {
      '&[currentslide="1.8"]': {
        display: 'none!important',
      },
    },
    '&&': {
      left: 'initial !important',
      right: '-1rem !important',
      transform: 'rotate(0)',
      color: '#1992f0',
      background:
        'linear-gradient(to right, rgba(255, 0, 0, 0), #ffffff)!important',
      border: 'none',
      width: '70px',
      height: '100%',
      top: '-5%!important',
      borderRadius: '0px',
    },
    '& svg': {
      animation: `${bounceInRight} 3s infinite`,
      top: '9.5%!important',
      right: '1.5rem!important',
      width: '1.8rem!important',
      height: '1.8rem!important',
    },
  },

  arrowContainerSimpleLeft: {
    '&&': {
      marginRight: '4.75rem',
    },
  },
  arrowContainerSimpleLeftTR: {
    '&&': {
      marginRight: '3.75rem',
      animation: 'none',
      display: 'none!important',
    },
  },

  breadcrumbs: {
    '&&': {
      [theme.breakpoints.down('md')]: {
        bottom: '-2em',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    arrowHideOnLast: {
      '& [currentslide="1.8"]': {
        '& svg': {
          display: 'none!important',
        },
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    arrowHideOnLast: {
      '& [currentslide="0.7999999999999998"]': {
        '& svg': {
          display: 'none!important',
        },
      },
    },
  },
  carousel: {
    '& [tabindex]': {
      '&:focus': {
        outline: 'none !important',
      },
    },

    width: '100%',
    overflow: 'hidden!important',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: '100vw',
    },
  },

  carouselZipJob: {
    marginTop: '1.5rem',
  },
}))

export default PricePackageCarousel
