import { useEffect } from 'react'
// @ts-ignore
import { gtag } from 'ga-gtag'
import { useTranslation } from 'react-i18next'

import useZipjobPackages from '@talentinc/gatsby-theme-ecom/hooks/useZipjobPackages'
import {
  GAConstants,
  ZipJobConstants,
} from '@talentinc/gatsby-theme-ecom/types/constants'

interface PackageProps {
  // @TODO: Clean the commented code once the GA4 config is stable
  // name: string
  // id: string
  // category: string
  price: number
  // list: string
  // brand: string
  // position: number
  item_name: string
  index: number
  item_brand: string
  item_category: string
  item_list_name: string
}

/**
 * useZJEcommEvents is a hook that fires the 'view_item_list' and 'view_item'
 * ecommerce events per user session using gtag.js API
 */
export default function useZJEcommEvents(): void {
  const {
    standardPackage,
    proPackage,
    elitePackage,
    executiveExpandedPackage,
  } = useZipjobPackages()
  const { i18n } = useTranslation()
  const tRefGa = 'zipjob-purchase-footer.packages.ga'
  const tRefSlugs = 'slugs'

  useEffect(() => {
    const isViewItemListFired =
      sessionStorage.getItem(GAConstants.EventViewItemList) ===
      GAConstants.Fired

    const isViewItemFired =
      sessionStorage.getItem(GAConstants.EventViewItem) === GAConstants.Fired

    if (
      !isViewItemListFired &&
      window.location.pathname.startsWith(i18n.t(`${tRefSlugs}.services`))
    ) {
      const purchasePackages = [standardPackage, proPackage, elitePackage]
      const items: PackageProps[] = []
      purchasePackages.forEach((card, index) => {
        items.push({
          // @TODO: Clean the commented code once the GA4 config is stable
          // @TODO: Add discount token and associated params
          // name: card.packageName,
          // id: card.packageName,
          // category: 'plans',
          price: card.price,
          // list: 'Services page',
          // brand: 'ZipJob',
          // position: index,
          item_name: card.packageName,
          index,
          item_brand: ZipJobConstants.BrandName,
          item_category: i18n.t(`${tRefGa}.default.itemCategory`),
          item_list_name: i18n.t(`${tRefGa}.default.itemListName`),
        })
      })
      // @TODO: Clean the commented code once the GA4 config is stable
      // sendEvent({
      //   event: 'e-commerce',
      //   variables: {},
      //   gtmPayload: {
      //     event: 'e-commerce',
      //     ecommerce: {
      //       detail: {
      //         products: products,
      //       },
      //     },
      //     eventAction: 'View Packages',
      //     eventCategory: 'Ecommerce',
      //     eventLabel: 'View All Packages on Service Page',
      //   },
      // })
      gtag(GAConstants.Event, GAConstants.EventViewItemList, { items })

      // Set a key in sessionStorage to avoid re-triggering the event
      // https://github.com/gatsbyjs/gatsby/issues/29011
      sessionStorage.setItem(GAConstants.EventViewItemList, GAConstants.Fired)
    }

    if (
      !isViewItemFired &&
      window.location.pathname.startsWith(
        i18n.t(`${tRefSlugs}.executiveResumeService`)
      )
    ) {
      gtag(GAConstants.Event, GAConstants.EventViewItem, {
        currency: 'USD',
        value: executiveExpandedPackage.price,
        items: [
          {
            // @TODO: Add discount token and associated params
            item_name: executiveExpandedPackage.packageName,
            item_brand: ZipJobConstants.BrandName,
            item_category: i18n.t(`${tRefGa}.executive.itemCategory`),
            item_list_name: i18n.t(`${tRefGa}.executive.itemListName`),
            price: executiveExpandedPackage.price,
          },
        ],
      })

      // Set a key in sessionStorage to avoid re-triggering the event
      // https://github.com/gatsbyjs/gatsby/issues/29011
      sessionStorage.setItem(GAConstants.EventViewItem, GAConstants.Fired)
    }
  }, [
    executiveExpandedPackage.packageName,
    executiveExpandedPackage.price,
    standardPackage,
    proPackage,
    elitePackage,
    i18n,
  ])
}
