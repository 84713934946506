import React from 'react'
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  ReviewItem as ReviewItemType,
  ReviewItemVariants,
} from '../../types/review'
import RichText from '../RichText'
import NavigationGroupLink from '../NavigationGroup/NavigationGroupLink'
import { useCommonStyles } from '../../utils/styles'

interface Props {
  review: ReviewItemType
}

const ReviewItem: React.FC<Props> = ({ review }) => {
  const { classes, cx } = useStyles()
  const { classes: commonClasses } = useCommonStyles()
  const { t } = useTranslation()

  return (
    <>
      {review.variant === ReviewItemVariants.WithImage && (
        <Card
          className={cx(classes.card, commonClasses.hideScrollbar)}
          elevation={0}
        >
          <CardHeader
            avatar={
              <Avatar
                alt={
                  review.reviewerImage.description &&
                  review.reviewerImage.description.length > 0
                    ? review.reviewerImage.description
                    : review.reviewerName
                }
                className={classes.avatar}
                src={review.reviewerImage.file.url}
              />
            }
            //@ts-ignore
            subheader={t(`reviewPage.profession`, {
              count: review.experience,
              yearCount: review.experience,
              profession: review.reviewerProfession,
            })}
            title={review.reviewerName}
          />
          <CardContent>
            <RichText rawBody={review.review.raw} />
          </CardContent>
        </Card>
      )}
      {review.variant === ReviewItemVariants.WithoutImage && (
        <Card className={classes.cardList} elevation={0}>
          <CardHeader
            title={<NavigationGroupLink link={review.packageBought} />}
            titleTypographyProps={{
              className: classes.title,
              color: 'primary',
            }}
          />

          <CardContent className={classes.cardContent}>
            <Box
              className={cx(
                classes.textBox,
                commonClasses.hideScrollbar
              )}
            >
              <RichText rawBody={review.review.raw} />
            </Box>
            <Box>
              <Typography className={classes.person}>
                {`${review.reviewerName}`}
                {review.reviewerLocation?.trim()
                  ? ` (${review.reviewerLocation.trim()})`
                  : null}
              </Typography>
              <Typography className={classes.profession}>
                {
                  //@ts-ignore
                  t(`reviewPage.profession`, {
                    count: review.experience,
                    yearCount: review.experience,
                    profession: review.reviewerProfession,
                  })
                }
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: '4.5rem',
    width: '4.5rem',
  },
  card: {
    height: '24.75rem',
    overflow: 'scroll',
    width: '95%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '17rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      height: 'initial',
    },
  },
  cardList: {
    height: '21.63rem',
    margin: '1rem 0',
    width: 'calc(50% - 1em)',
    background: theme.palette.background.default,
    [theme.breakpoints.down('lg')]: {
      height: 'initial',
      width: '100%',
    },
  },
  person: {
    fontWeight: 600,
  },
  profession: {
    color: theme.palette.secondary.dark,
  },
  textBox: {
    maxHeight: '10rem',
    overflow: 'scroll',
    [theme.breakpoints.down('lg')]: {
      maxHeight: 'initial',
      marginBottom: '1.5rem',
      overflow: 'initial',
    },
  },
  title: {
    fontSize: '1.0625rem',
    fontWeight: 600,
    textTransform: 'initial',
  },
}))

export default ReviewItem
