import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  Banner as BannerType,
  BannerVariants,
  CustomComponent as CustomComponentType,
  TextColors,
} from '../../types/landingPageV2'
import { TextColumn as TextColumnType } from '../../types/heroTextSection'
import {
  CommonClasses,
  SuccessStoryClasses,
  BindToFields,
} from '../../types/contentfulAttributes'
import useBrand from '../../hooks/useBrand'
import getColor from '../../utils/colors'
import formatContentfulClasses from '../../utils/customClasses'
import { createContentfulClassGetter } from '../../utils/contentful'
import { withTwoPassRenderer } from '../../hocs/withTwoPassRenderer'
import useHide from '../../hooks/useHide'
import { TextColumnInsert } from '../ContentfulEntries/TextColumnInsert'
import  CustomComponent from '../CustomComponent'
import  Image from '../ContentfulEntries/Image'
import  RichText from '../RichText'

export interface BannerProps {
  banner: BannerType
  sectionClass?: string
}

const Banner: React.FC<BannerProps> = ({ banner, sectionClass }) => {
  const { classes, cx } = useStyles(banner)
  const brand = useBrand()
  const applyContentfulStyles = createContentfulClassGetter(banner.css)
  const hideClasses = useHide(banner.hideOn)

  return (
    <Box
      className={cx(
        applyContentfulStyles(BindToFields.Main),
        classes.outterBox,
        ...formatContentfulClasses(banner.codeClasses),
        hideClasses
      )}
    >
      <Box
        className={cx(classes.box, sectionClass, {
          [classes.boxTR]: brand.name !== 'ZipJob',
          [classes.boxFullwidth]: banner.variant === BannerVariants.FullWidth,
          [classes.boxFullwidthNarrow]:
            banner.variant === BannerVariants.FullWidthNarrow,
          [classes.boxFullwidthTR]:
            brand.name !== 'ZipJob' &&
            (banner.variant === BannerVariants.FullWidth ||
              banner.variant === BannerVariants.FullWidthNarrow),
          [classes.boxTextDark]: banner.textColor === TextColors.Dark,
        })}
      >
        {banner.text && (
          <Box className={classes.textBox}>
            <RichText
              rawBody={banner.text.raw}
              references={banner.text.references}
              options={{
                props: {
                  [BLOCKS.HEADING_6]: {
                    component: 'caption',
                    className: classes.caption,
                  },
                },
              }}
            />
          </Box>
        )}
        {banner.components && (
          <Box
            className={cx(
              classes.componentsBox,
              banner.codeClasses?.includes(CommonClasses.MultiCompoBanner) &&
                classes.multiCompoBanner,
              applyContentfulStyles(BindToFields.ComponentsWrapper)
            )}
          >
            {banner.components.map((component) => (
              <BannerItem item={component} key={component.contentful_id} />
            ))}
          </Box>
        )}
        {banner.images && (
          <Box
            className={cx(
              classes.imagesBox,
              banner.codeClasses?.includes(SuccessStoryClasses.Banner) &&
                classes.successStoryBanner
            )}
          >
            {banner.images.map((image) => (
              <Image
                asset={image}
                className={
                  banner.codeClasses?.includes(SuccessStoryClasses.Banner)
                    ? classes.successStoryImage
                    : classes.image
                }
                key={image.contentful_id}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const BannerItem: React.FC<{
  item: TextColumnType | CustomComponentType
}> = ({ item }) => {
  const { classes, cx } = useStyles({})
  const brand = useBrand()

  switch (item.__typename) {
    case 'ContentfulTextColumn':
      return (
        <TextColumnInsert
          textColumnInsert={item}
          containerClass={classes.textColumnContainer}
          textClass={cx({
            [classes.textColumnText]: brand.name === 'ZipJob',
          })}
        />
      )
    case 'ContentfulCustomComponent':
      return (
        <CustomComponent
          item={item}
          containerClass={classes.trustpilotContainer}
        />
      )
    default:
      return null
  }
}

export const useStyles = makeStyles<any>()((theme, banner) => {
  const boxBgColor = getColor(banner?.backgroundColor, theme)

  const a400 = banner?.color === 'Beige' ? theme.colors.gray.a400 : 'unset'
  const dark =
    banner?.color === 'Beige' ? theme.palette.secondary.dark : 'unset'

  return {
    box: {
      alignItems: 'center',
      color: theme.colors.gray.a400,
      display: 'flex',
      flexDirection: 'column' as any,
      paddingBottom: '2.49em',
      paddingTop: '1.88em',
      backgroundColor: boxBgColor,
      maxWidth: '100vw',
    },
    boxTR: {
      color: theme.palette.secondary.dark,
      paddingBottom: '4rem',
      paddingTop: '4rem',
    },
    boxFullwidth: {
      color: a400,
      paddingBottom: '2.69em',
      paddingTop: '2.64em',
      [theme.breakpoints.down('md')]: {
        width: '66em',
      },
    },
    boxFullwidthNarrow: {
      color: a400,
      paddingBottom: '.2em',
      paddingTop: '1.2em',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    boxFullwidthTR: {
      color: dark,
    },
    boxTextDark: {
      color: `${theme.palette.primary.dark} !important`,
    },
    componentsBox: {
      display: 'flex',

      '& > *': {
        margin: '0 2.5%',

        [theme.breakpoints.up('md')]: {
          '&:first-child': {
            marginLeft: '0',
          },
          '&:last-child': {
            marginRight: '0',
          },
        },
      },
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
          marginBottom: '1rem',
        },
      },
    },
    image: {
      margin: '0 1.63em',
      [theme.breakpoints.down('md')]: {
        margin: '.99em 1em',
      },
    },
    successStoryImage: {
      margin: 0,
    },
    imagesBox: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'contents !important',
        justifyContent: 'space-around',
      },
    },
    successStoryBanner: {
      gap: '1.25rem',

      '@media (min-width: 768px)': {
        gap: '3.25rem',
        maxWidth: '90%',
      },
    },
    outterBox: {
      overflow: 'auto',
      width: '100%',
    },
    textBox: {
      marginBottom: '1.13em',
      textAlign: 'center',
      '& br': {
        '&:last-child': {
          display: 'none',
        },
      },
    },
    textColumnContainer: {
      height: 'unset',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
        textAlign: 'left',
      },
    },
    textColumnText: {
      marginBottom: '1.11em',
      maxWidth: '100%',

      '&:last-child': {
        marginBottom: '0',
      },
    },
    trustpilotContainer: {
      width: '100%',
      height: 'unset',
      justifyContent: 'space-between',

      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
        order: '-1',
        marginLeft: 0,
        marginBottom: 16
      },
    },
    caption: {
      display: 'inline',
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    multiCompoBanner: {
      gap: '2rem',

      [theme.breakpoints.up(768)]: {
        maxWidth: '63%',
      },

      '& div': {
        [theme.breakpoints.up(768)]: {
          margin: 0,
        },
      },

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        maxWidth: '85%',
      },
    },
  }
})

export default withTwoPassRenderer(Banner)
