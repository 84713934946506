import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import useTidyLink from '../../hooks/useTidyLink'
import useCtaClickEvent from '../../hooks/useHomepageCTAClickEvent'

type Props = Omit<ButtonProps, 'component' | 'href'> & {
  to?: string
  href?: string
  homepageEvent?: boolean
  position?: string
}

function HomePageLinkButton(props: Props) {
  const { onClick, position = 'main' } = props

  const tidyTheLink = useTidyLink()
  const link = tidyTheLink(props.to ?? props.href)
  const { track } = useCtaClickEvent()

  const handleNavigation = (e: React.MouseEvent<HTMLButtonElement>) => {
    track({
      cta_text: e.currentTarget.textContent,
      cta_link: link,
      position,
    })
    e.preventDefault()
    document.location.href = props.href
    onClick?.(e)
  }

  return <Button {...props} onClick={handleNavigation} />
}

export default HomePageLinkButton
