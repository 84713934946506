import React from 'react'
import {
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Close } from '@mui/icons-material'
import {
  useMixpanelCTAClickTracking,
  useMpEventTrackOn,
} from '../../utils/mixpanel'
import { MixpanelEventTypes, MixpanelEvents } from '../../types/mixpanel'
import { useFETelemetry } from '../../hooks/useTelemetry'

interface PackageModalProps {
  open: boolean
  handleClose: () => void
  handleShowOriginalPackages: () => void
  handleShowNewPackages: () => void
}

const HEROSECTION_IMAGE_JOB_SEARCH =
  'https://images.ctfassets.net/7thvzrs93dvf/39uc4D8CJBxxyo5F7lp6uN/77e89ad42df894c77dbe492452bcc6a8/Job_Search.png'
const HEROSECTION_IMAGE_RESUME_WRITING =
  'https://images.ctfassets.net/7thvzrs93dvf/4Qk5EA1Kg47Ql3VSKRK672/ce853213fd4f1a29cbec1018d39fff69/Frame.png'

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    borderRadius: '24px',
  },
  dialogContent: {
    backgroundColor: '#EAF6FF',
    padding: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EAF6FF',
    padding: theme.spacing(2),
    borderRadius: '24px 24px 0 0',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  closeButtonContainer: {
    alignSelf: 'flex-end',
  },
  closeButton: {
    color: '#808080', // gray color
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.5)',
    },
  },
  title: {
    fontWeight: 400,
    fontSize: '43px',
    lineHeight: '43px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '21.5px',
      lineHeight: '21.5px',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '19px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '9.5px',
      lineHeight: '12px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '19px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    minHeight: '75px',
    [theme.breakpoints.down('md')]: {
      fontSize: '9.5px',
      lineHeight: '12px',
      minHeight: '17.5px',
    },
  },
  descriptionOne: {
    fontWeight: 400,
    fontSize: '43px',
    lineHeight: '43px',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '21.5px',
      lineHeight: '21.5px',
    },
  },
  card: {
    borderRadius: '24px',
    boxShadow: 'none',
    padding: theme.spacing(3), // Add space inside the card
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '0px',
      paddingTop: '0px',
    },
  },
  icon: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    width: '40px', // Reduced width
    height: '40px', // Reduced height
    [theme.breakpoints.down('md')]: {
      width: '20px',
      height: '20px',
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
  },
  button: {
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // More transparent backdrop
  },
}))

const PackageModal: React.FC<PackageModalProps> = ({
  open,
  handleClose,
  handleShowOriginalPackages,
  handleShowNewPackages,
}) => {
  const { classes } = useStyles()
  const telemetry = useFETelemetry()

  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const jobSearchEventTrackOn = useMpEventTrackOn(
    MixpanelEventTypes.JobSearchEvent
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
      BackdropProps={{ className: classes.backdrop }} // Apply custom backdrop style
    >
      <Box className={classes.titleContainer}>
        <Box className={classes.closeButtonContainer}>
          <IconButton
            data-testid="close-button"
            onClick={() => {
              handleClose()
            }}
            className={classes.closeButton}
            size="large"
          >
            <Close />
          </IconButton>
        </Box>
        <Typography variant="h2" className={classes.title}>
          Perfect Support for Your Career Journey
        </Typography>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <img
                  src={HEROSECTION_IMAGE_RESUME_WRITING}
                  alt="Resume Writing"
                  className={classes.icon}
                />
                <Typography variant="h3" className={classes.subtitle}>
                  Professional
                </Typography>
                <Typography
                  color="textSecondary"
                  className={classes.descriptionOne}
                >
                  Resume Writing Services
                </Typography>
                <Typography variant="body2" className={classes.description}>
                  Get a resume that highlights your strengths and stands out to
                  employers.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  data-testid="enhance-my-resume-modal-button"
                  onAuxClick={(e) => {
                    handleMpCTAClickTracking(e.currentTarget.textContent, '', {
                      eventName: MixpanelEvents.EnhanceMyResumeClickModal,
                      trackingData: {
                        event_type: MixpanelEventTypes.JobSearchEvent,
                      },
                      trackOn: jobSearchEventTrackOn,
                    })
                    telemetry.track({ event: 'enhance_my_resume_click_modal' })
                    handleShowOriginalPackages()
                    handleClose()
                  }}
                  onClick={(e) => {
                    handleMpCTAClickTracking(e.currentTarget.textContent, '', {
                      eventName: MixpanelEvents.EnhanceMyResumeClickModal,
                      trackingData: {
                        event_type: MixpanelEventTypes.JobSearchEvent,
                      },
                      trackOn: jobSearchEventTrackOn,
                    })
                    telemetry.track({ event: 'enhance_my_resume_click_modal' })
                    handleShowOriginalPackages()
                    handleClose()
                  }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  className={classes.button}
                >
                  Enhance my resume
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <img
                  src={HEROSECTION_IMAGE_JOB_SEARCH}
                  alt="Job Search"
                  className={classes.icon}
                />
                <Typography className={classes.subtitle}>
                  Comprehensive
                </Typography>
                <Typography
                  color="textSecondary"
                  className={classes.descriptionOne}
                >
                  Job Search Services
                </Typography>
                <Typography variant="body2" className={classes.description}>
                  Explore our job search solutions to streamline your career and
                  boost your success.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  data-testid="get-expert-help-modal-button"
                  onAuxClick={(e) => {
                    handleMpCTAClickTracking(e.currentTarget.textContent, '', {
                      eventName: MixpanelEvents.GetExpertHelpClickModal,
                      trackingData: {
                        event_type: MixpanelEventTypes.JobSearchEvent,
                      },
                      trackOn: jobSearchEventTrackOn,
                    })
                    telemetry.track({ event: 'get_expert_help_modal_click' })
                    handleShowNewPackages()
                    handleClose()
                  }}
                  onClick={(e) => {
                    handleMpCTAClickTracking(e.currentTarget.textContent, '', {
                      eventName: MixpanelEvents.GetExpertHelpClickModal,
                      trackingData: {
                        event_type: MixpanelEventTypes.JobSearchEvent,
                      },
                      trackOn: jobSearchEventTrackOn,
                    })
                    telemetry.track({ event: 'get_expert_help_modal_click' })
                    handleShowNewPackages()
                    handleClose()
                  }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  className={classes.button}
                >
                  Get Expert Help
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default PackageModal
