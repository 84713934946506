import React, { useCallback, useState } from 'react'
import { useMediaQuery, useTheme, Grid, Box, Container } from '@mui/material'
import { PackageVariant, ProductPackageDetail } from '../../types/productPageV2'
import { BrandNames } from '../../types/brand'
import { BindToFields, CssEntry } from '../../types/contentfulAttributes'
import { createContentfulClassGetter } from '../../utils/contentful'
import { useSKU } from '../../hooks/useSKUs'
import useBrand from '../../hooks/useBrand'
import useDiscountToken from '../../hooks/useDiscountToken'
import PricePackageCarousel from './PricePackageCarousel'
import { usePricePageStyles } from './styles'
import PurchaseCard from './NewPurchaseCard'
import PurchaseCardZipJob from '../ProductPackages/PurchaseCardZipJob'

export interface ProductPackageDetailProps {
  productPackageDetail: ProductPackageDetail
}

interface Props {
  productPackageRows: ProductPackageDetail[]
  css: CssEntry[] | null
}
function useResponsiveCondition() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'))
  return isDesktop
}

const NewProductPackagesContainer: React.FC<Props> = ({
  productPackageRows,
  css,
}) => {
  const isDesktop = useResponsiveCondition()

  const [expanded, setExpanded] = useState<boolean>(false)
  const { classes, cx } = usePricePageStyles()
  const brand = useBrand()
  const applyContentfulStyles = createContentfulClassGetter(css)

  // Toggles the shared expanded state
  const handleExpandToggle = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded)
  }, [])

  return (
    <Box
      className={cx({
        [classes.cards]: true,
        [classes.cardsZipjob]: brand.name?.toLowerCase() === BrandNames.zipJob,
        [classes.cards4]: productPackageRows.length === 2,
      })}
    >
      <Container
        className={applyContentfulStyles(BindToFields.OutermostWrapper)}
        disableGutters
      >
        {isDesktop ? (
          <div className={classes.responsiveGrid}>
            <PricePackageCarousel>
              {productPackageRows.map((productPackageDetail) => (
                <Grid
                  key={productPackageDetail.contentful_id}
                  item
                  className={classes.gridItem}
                >
                  {productPackageDetail.variant === PackageVariant.free ||
                  !productPackageDetail.productPackage ? (
                    <Grid>
                      <PurchaseCardWithoutSku
                        productPackageDetail={productPackageDetail}
                        expanded={expanded}
                        onExpandClick={handleExpandToggle}
                      />
                    </Grid>
                  ) : (
                    <Grid>
                      <PurchaseCardWithSku
                        productPackageDetail={productPackageDetail}
                        expanded={expanded}
                        onExpandClick={handleExpandToggle}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </PricePackageCarousel>
          </div>
        ) : (
          <Grid container spacing={2} className={classes.responsiveContainer}>
            {productPackageRows.slice(0, 3).map((productPackageDetail) => (
              <Grid
                item
                className={classes.gridItem}
                key={productPackageDetail.contentful_id}
              >
                {productPackageDetail.variant === PackageVariant.free ||
                !productPackageDetail.productPackage ? (
                  <Grid>
                    <PurchaseCardWithoutSku
                      productPackageDetail={productPackageDetail}
                      expanded={expanded}
                      onExpandClick={handleExpandToggle}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <PurchaseCardWithSku
                      productPackageDetail={productPackageDetail}
                      expanded={expanded}
                      onExpandClick={handleExpandToggle}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  )
}

export const PurchaseCardWithoutSku: React.FC<
  ProductPackageDetailProps & {
    expanded: boolean
    onExpandClick: () => void
  }
> = ({ productPackageDetail, expanded, onExpandClick }) => {
  const brand = useBrand()
  return (
    <>
      {brand.name === 'ZipJob' ? (
        <PurchaseCardZipJob {...productPackageDetail} />
      ) : (
        <PurchaseCard
          {...productPackageDetail}
          expanded={expanded}
          onExpandClick={onExpandClick}
        />
      )}
    </>
  )
}

export const PurchaseCardWithSku: React.FC<
  ProductPackageDetailProps & {
    expanded: boolean
    onExpandClick: () => void
  }
> = ({ productPackageDetail, expanded, onExpandClick }) => {
  const urlDiscountToken = useDiscountToken()
  const sku = useSKU(
    productPackageDetail.productPackage.items,
    productPackageDetail.productPackage.planCode,
    urlDiscountToken ?? productPackageDetail.discountToken,
    productPackageDetail.productPackage.sort
  )
  const brand = useBrand()

  return (
    <>
      {brand.name === 'ZipJob' ? (
        <PurchaseCardZipJob
          {...productPackageDetail}
          planCode={sku?.plan_code}
          amount={sku?.amount}
          discountedAmount={sku?.discountedAmount}
          title={productPackageDetail.title || sku?.plan_short_name}
          formattedAmount={sku?.formattedOriginalAmount}
          formattedDiscount={sku?.formattedDiscount}
          purchasePath={sku?.purchasePath}
          sort={sku?.sort}
        />
      ) : (
        <PurchaseCard
          {...productPackageDetail}
          planCode={sku?.plan_code}
          amount={sku?.amount}
          discountedAmount={sku?.discountedAmount}
          title={productPackageDetail.title || sku?.plan_short_name}
          formattedAmount={sku?.formattedOriginalAmount}
          formattedDiscount={sku?.formattedDiscount}
          purchasePath={sku?.purchasePath}
          alternatePlan={sku?.alternate_plan}
          expanded={expanded}
          onExpandClick={onExpandClick}
        />
      )}
    </>
  )
}

export default NewProductPackagesContainer
