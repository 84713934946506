import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemIcon,
  List,
  useTheme,
  Container,
  CardActions,
  useMediaQuery,
  Collapse,
  Divider,
  Grid,
} from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { BLOCKS } from '@contentful/rich-text-types'
import { useTranslation } from 'react-i18next'
import { BGVariants } from '../../types/heroTextSection'
import { PackageVariant, SellingPoint } from '../../types/productPageV2'
import { Button, PurchaseButton } from '../../types/page'
import { ExternalLink } from '../../types/page'
import useBrand from '../../hooks/useBrand'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import formatContentfulClasses from '../../utils/customClasses'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'
import CheckMarkSVG from '../../images/checkmarkblue.inline.svg'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BindToFields, CssEntry } from '../../types/contentfulAttributes'
import { MIN_AFFIRM_AMOUNT } from '../../types/constants'
import { AlternatePlan } from '../../hooks/useSKUs'
import { hasNonNullProperties } from '../../utils/utils'
import { SplitPaymentLink } from './NewSplitPaymentLinkV6'
import CrosDisabled from '../../images/crosmark-disabled.inline.svg'
import fundamentalsBenefits from './PackageBenefits/fundamentalsBenefits.json'
import standardBenefits from './PackageBenefits/standardBenefits.json'
import enhancedBenefits from './PackageBenefits/enhancedBenefits.json'
import foundationBenefits from './PackageBenefits/foundationBenefits.json'
import corepBenefits from './PackageBenefits/corepBenefits.json'
import plusBenefits from './PackageBenefits/plusBenefits.json'
import premierBenefits from './PackageBenefits/premierBenefits.json'
import signatureBenefits from './PackageBenefits/signatureBenefits.json'
import { usePricePageStyles } from './styles'
import NavigationGroupLink from '../NavigationGroup/NavigationGroupLink'
import ProductPriceInsert from './NewProductPriceInsertV6'
import RichText from '../RichText'
import LinkButton from '../Link/LinkButton'
import AffirmNewTest from '../AffirmNewTest'
import { useBETelemetry } from '../../hooks/useTelemetry' // Import the telemetry hook

export interface PurchaseCardProps {
  title?: string | null
  subtitle?: string | null
  description: string
  sellingPoints: SellingPoint[]
  cta: PurchaseButton | Button | null
  secondaryCta: ExternalLink
  backgroundColor: string
  discountToken: string
  variant: string
  highlightPackage: boolean
  highlightTag: string
  originalAmount?: number
  amount?: number
  discountedAmount?: number
  formattedAmount?: string
  formattedDiscount?: string | null
  planCode?: string
  purchasePath?: string
  priceOverride?: string
  codeClasses: string[] | null
  css: CssEntry[] | null
  alternatePlan?: AlternatePlan
}

const PurchaseCard: React.FC<PurchaseCardProps> = ({
  title,
  subtitle,
  description,
  sellingPoints,
  amount = 0,
  formattedAmount,
  formattedDiscount,
  planCode,
  cta,
  backgroundColor,
  variant,
  highlightPackage,
  highlightTag,
  purchasePath,
  priceOverride,
  codeClasses,
  css,
  alternatePlan,
}) => {
  const { classes, cx } = usePricePageStyles()
  const theme = useTheme()
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()
  const brand = useBrand()
  const handlebarsContext = useHandlebarsContext()
  const [expanded, setExpanded] = useState(false)
  const telemetry = useBETelemetry() // Initialize telemetry hook

  const isPricingPagePackage = codeClasses?.includes('pricing-package')
  const isPremierPackage = codeClasses?.includes('premier') // Check if 'elite' class is present
  const isSignaturePackage = codeClasses?.includes('signature') // Check if 'apex' class is present

  const applyContentfulStyles = createContentfulClassGetter(css)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const getLinkButton = (isHover: boolean): JSX.Element | null => {
    if (cta) {
      const trackButtonClick = () => {
        telemetry.track({
          event: 'click_order_now',
          properties: {
            label: title || 'Unknown Plan', // Use title as label or default to 'Unknown Plan'
            plan_code: planCode || 'Unknown Plan Code',
            hover: isHover ? 'True' : 'False', // Convert boolean to string
            section: 'Resume Writing', // Replace with dynamic section if needed
            url: window.location.href,
          },
        })
      }

      if (planCode) {
        if (cta.__typename === 'ContentfulButton') {
          return (
            <LinkButton
              className={cx(...formatContentfulClasses(cta.codeClasses))}
              href={purchasePath}
              color={cta.color === 'default' ? 'primary' : cta.color}
              variant={cta.variant}
              fullWidth
              mixpanelTracking={cta.mixpanelTracking}
              onClick={trackButtonClick}
            >
              {cta.buttonText ?? undefined}
            </LinkButton>
          )
        }

        if (cta.action) {
          if ('slug' in cta.action) {
            return (
              <LinkButton
                className={cx(...formatContentfulClasses(cta.codeClasses))}
                to={cta.action.slug ?? purchasePath}
                color={cta.color === 'default' ? 'primary' : cta.color}
                variant={cta.variant}
                fullWidth
                mixpanelTracking={cta.mixpanelTracking}
                onClick={trackButtonClick}
              >
                {cta.buttonText ?? undefined}
              </LinkButton>
            )
          }

          if ('url' in cta.action) {
            return (
              <LinkButton
                className={cx(...formatContentfulClasses(cta.codeClasses))}
                href={cta.action.url}
                color={cta.color === 'default' ? 'primary' : cta.color}
                variant={cta.variant}
                fullWidth
                mixpanelTracking={cta.mixpanelTracking}
                onClick={trackButtonClick}
              >
                {cta.buttonText ?? undefined}
              </LinkButton>
            )
          }
        }
      }
      return (
        <NavigationGroupLink
          key={cta.contentful_id}
          link={cta as Button}
          className={cx(...formatContentfulClasses(cta.codeClasses))}
          onClick={trackButtonClick}
        />
      )
    }

    return null
  }

  let packageBenefits

  if (codeClasses?.includes('fundamentals')) {
    packageBenefits = fundamentalsBenefits
  } else if (codeClasses?.includes('standard')) {
    packageBenefits = standardBenefits
  } else if (codeClasses?.includes('enhanced')) {
    packageBenefits = enhancedBenefits
  } else if (codeClasses?.includes('foundation')) {
    packageBenefits = foundationBenefits
  } else if (codeClasses?.includes('corep')) {
    packageBenefits = corepBenefits
  } else if (codeClasses?.includes('plus')) {
    packageBenefits = plusBenefits
  } else if (codeClasses?.includes('premier')) {
    packageBenefits = premierBenefits
  } else if (codeClasses?.includes('signature')) {
    packageBenefits = signatureBenefits
  }

  const hoverContainerClass = cx(classes.hoverContainer, {
    [classes.hoverContainerFundamentals]: codeClasses?.includes('fundamentals'),
    [classes.hoverContainerStandard]: codeClasses?.includes('standard'),
    [classes.hoverContainerEnhanced]: codeClasses?.includes('enhanced'),
    [classes.hoverContainerFoundation]: codeClasses?.includes('foundation'),
    [classes.hoverContainerCore]: codeClasses?.includes('corep'),
    [classes.hoverContainerPlus]: codeClasses?.includes('plus'),
    [classes.hoverContainerPremier]: codeClasses?.includes('premier'),
    [classes.hoverContainerSignature]: codeClasses?.includes('signature'),
  })

  return (
    <Container
      className={cx(classes.noRightPadding, {
        [classes.fullWidth]: isPremierPackage || isSignaturePackage,
      })}
    >
      <Card
        className={cx(
          classes.priceCard,

          {
            [classes.noMinHeight]:
              (isPremierPackage || isSignaturePackage) && isMdOrLarger, // Apply noMinHeight style if it's the elite or apex package and screen is md or larger
            [classes.premierCard]:
              (isPremierPackage || isSignaturePackage) && isMdOrLarger, // Apply eliteCard style if it's the elite or apex package and screen is md or larger
            [classes.cardDarkPrimary]:
              backgroundColor === BGVariants.DarkPrimary,
            [classes.cardPopular]: highlightPackage && isPricingPagePackage,
            [classes.threeColPackage]:
              codeClasses?.includes('three-col-package'),
          },
          formatContentfulClasses(codeClasses),
          applyContentfulStyles(BindToFields.Main)
        )}
        elevation={0}
      >
        {highlightPackage ? (
          <Typography
            className={cx(
              classes.banner,
              isPricingPagePackage
                ? classes.taggedPopularBanner
                : classes.popularBanner,
              applyContentfulStyles(BindToFields.HighlightTag)
            )}
            component="p"
            variant="overline"
          >
            {highlightTag ? highlightTag : t('purchaseCard.popular')}
          </Typography>
        ) : (
          <Typography
            className={cx(
              { [classes.banner]: true },
              codeClasses?.includes('three-col-package')
                ? classes.hideOnSmall3Col
                : classes.hideOnSmall,
              applyContentfulStyles(BindToFields.HighlightTag)
            )}
            component="p"
            variant="overline"
          ></Typography>
        )}
        <CardContent
          className={cx({
            [classes.priceCardContent]:
              !(isPremierPackage || isSignaturePackage) || !isMdOrLarger, // Exclude priceCardContent for elite or apex packages on desktop
            [classes.borderRadius]: !highlightPackage && !isPricingPagePackage,
            [classes.contentExpanded]:
              variant === PackageVariant.expandedPackage,
            [classes.contentMostPopular]:
              highlightPackage && !isPricingPagePackage,
            [classes.premierContent]:
              (isPremierPackage || isSignaturePackage) && isMdOrLarger, // Apply eliteContent style if it's the elite or apex package and screen is md or larger
          })}
        >
          {(isPremierPackage || isSignaturePackage) && isMdOrLarger ? (
            <Box
              className={classes.premierCard} // Apply flexbox layout for elite or apex package
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box className={classes.premierContentLeft}>
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.priceCardTitle}
                >
                  {title}
                </Typography>

                <Typography variant="h3" className={classes.priceCardSubtitle}>
                  {subtitle}
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.priceCardDescription}
                >
                  {insertHandlebarsTemplate(description, handlebarsContext)}
                </Typography>
              </Box>
              <Box className={classes.premierContentRight}>
                <Box className={classes.priceCardPrice} component="div">
                  <>
                    {formattedAmount ? (
                      <ProductPriceInsert
                        formattedAmount={formattedAmount}
                        formattedDiscount={formattedDiscount}
                        url={purchasePath}
                        linkPriceClass={classes.priceCardPrice}
                        originalPriceClass={classes.priceCardPrice}
                      />
                    ) : (
                      <>
                        {priceOverride ? (
                          <Typography className={classes.priceCardPrice}>
                            {priceOverride}
                          </Typography>
                        ) : (
                          <Typography className={classes.priceCardPrice}>
                            {t('purchaseCard.free')}
                          </Typography>
                        )}
                      </>
                    )}
                  </>

                  <Box className={classes.priceCardMonthlyPrice}>
                    {formattedAmount &&
                    planCode &&
                    !brand.name?.includes('TopCV') &&
                    amount > MIN_AFFIRM_AMOUNT ? (
                      <AffirmNewTest planCode={planCode} price={amount} />
                    ) : brand.isTopCvFr &&
                      hasNonNullProperties(alternatePlan) ? (
                      <SplitPaymentLink
                        planCode={alternatePlan.plan_code}
                        intervalTimes={alternatePlan.interval_times}
                        amount={alternatePlan.amount}
                      />
                    ) : (
                      <Typography className={classes.oneTimePayment}>
                        {t('purchaseCard.ineligibleForAffirm')}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <CardActions>{getLinkButton(false)}</CardActions>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                className={classes.titleAndPriceContainer} // Add a custom class for additional styling if needed
                component="div"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.priceCardTitle}
                >
                  {title}
                </Typography>

                <Typography variant="h3" className={classes.priceCardSubtitle}>
                  {subtitle}
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.priceCardDescription}
                >
                  {insertHandlebarsTemplate(description, handlebarsContext)}
                </Typography>

                <Box className={classes.priceCardPrice} component="div">
                  <>
                    {formattedAmount ? (
                      <ProductPriceInsert
                        formattedAmount={formattedAmount}
                        formattedDiscount={formattedDiscount}
                        url={purchasePath}
                        linkPriceClass={classes.priceCardPrice}
                        originalPriceClass={classes.priceCardPrice}
                      />
                    ) : (
                      <>
                        {priceOverride ? (
                          <Typography className={classes.priceCardPrice}>
                            {priceOverride}
                          </Typography>
                        ) : (
                          <Typography className={classes.priceCardPrice}>
                            {t('purchaseCard.free')}
                          </Typography>
                        )}
                      </>
                    )}
                  </>

                  <Box className={classes.priceCardMonthlyPrice}>
                    {formattedAmount &&
                    planCode &&
                    !brand.name?.includes('TopCV') &&
                    amount > MIN_AFFIRM_AMOUNT ? (
                      <AffirmNewTest planCode={planCode} price={amount} />
                    ) : brand.isTopCvFr &&
                      hasNonNullProperties(alternatePlan) ? (
                      <SplitPaymentLink
                        planCode={alternatePlan.plan_code}
                        intervalTimes={alternatePlan.interval_times}
                        amount={alternatePlan.amount}
                      />
                    ) : (
                      <Typography className={classes.oneTimePayment}>
                        {t('purchaseCard.ineligibleForAffirm')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Typography
                variant="h3"
                className={classes.priceCardSubtitleMobile}
              >
                {insertHandlebarsTemplate(description, handlebarsContext)}
              </Typography>

              <CardActions>{getLinkButton(false)}</CardActions>
            </>
          )}
        </CardContent>
        {!isPremierPackage &&
          !isSignaturePackage && ( // Conditionally render the selling points
            <CardContent className={classes.priceFeaturesList}>
              <List className={classes.priceListFeatures}>
                {sellingPoints.map((point, index) => {
                  if (isMdOrLarger && index >= 11) return null
                  const isDisabled = point.variant === 'disabled'
                  const featureIcon = isDisabled ? (
                    <CrosDisabled />
                  ) : (
                    <CheckMarkSVG />
                  )
                  const textClass = `${classes.priceCardFeature} ${
                    isDisabled ? classes.grayFeature : ''
                  }`

                  return (
                    <ListItem
                      key={point.contentful_id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0px',
                        paddingTop: '0px',
                        paddingBottom: '12px',
                        paddingLeft: isDisabled ? '13px' : '16px',
                      }}
                    >
                      <ListItemIcon
                        style={{ minWidth: '20px', marginRight: '10px' }}
                      >
                        {featureIcon}
                      </ListItemIcon>
                      <Typography variant="body1" className={textClass}>
                        <RichText
                          rawBody={point.sellingPointBody.raw}
                          references={point.sellingPointBody.references}
                          options={{
                            props: {
                              [BLOCKS.PARAGRAPH]: {
                                variant: 'body2',
                                className: textClass,
                              },
                            },
                          }}
                        />
                      </Typography>
                    </ListItem>
                  )
                })}
              </List>
            </CardContent>
          )}
        {/* Hover and Expand Section */}
        {packageBenefits && (
          <>
            <div className={hoverContainerClass}>
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={8}
                    style={{ textAlign: 'left', paddingLeft: '1%' }}
                  >
                    <Typography
                      variant="h3"
                      dangerouslySetInnerHTML={{
                        __html: packageBenefits.heading,
                      }}
                    />
                    {/* Dynamic heading */}
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <CardActions style={{ display: 'inline-flex' }}>
                      {getLinkButton(true)} {/* Pass true for hover state */}
                    </CardActions>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px 0' }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {packageBenefits.benefits
                      .slice(0, Math.ceil(packageBenefits.benefits.length / 2))
                      .map((benefit, index) => (
                        <ListItem
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginBottom: '0px',
                            paddingTop: '14px',
                            paddingBottom: '12px',
                          }}
                        >
                          <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <ListItemIcon
                              style={{ minWidth: '20px', marginRight: '10px' }}
                            >
                              <img
                                style={{ maxWidth: '20px' }}
                                src={benefit.icon}
                                alt={benefit.title}
                              />
                            </ListItemIcon>
                            <Typography
                              variant="h4"
                              className={classes.benefitTitle}
                            >
                              {benefit.title}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            style={{ marginTop: '8px' }}
                            className={classes.benefitDesc}
                            dangerouslySetInnerHTML={{
                              __html: benefit.description,
                            }}
                          />
                        </ListItem>
                      ))}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {packageBenefits.benefits
                      .slice(Math.ceil(packageBenefits.benefits.length / 2))
                      .map((benefit, index) => (
                        <ListItem
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginBottom: '0px',
                            paddingTop: '14px',
                            paddingBottom: '12px',
                          }}
                        >
                          <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <ListItemIcon
                              style={{ minWidth: '20px', marginRight: '10px' }}
                            >
                              <img
                                style={{ maxWidth: '20px' }}
                                src={benefit.icon}
                                alt={benefit.title}
                              />
                            </ListItemIcon>
                            <Typography
                              variant="h4"
                              className={classes.benefitTitle}
                            >
                              {benefit.title}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            style={{ marginTop: '8px' }}
                            className={classes.benefitDesc}
                            dangerouslySetInnerHTML={{
                              __html: benefit.description,
                            }}
                          />
                        </ListItem>
                      ))}
                  </Grid>
                </Grid>
              </CardContent>
            </div>
            <Box display={{ xs: 'block', md: 'none' }}>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                  style={{ paddingLeft: '0px', paddingRight: '0px' }}
                >
                  <List className={classes.priceListFeatures}>
                    {packageBenefits.benefits.map((benefit, index) => (
                      <ListItem
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          marginBottom: '0px',
                          paddingTop: '0px',
                          paddingBottom: '12px',
                          flexDirection: 'column',
                        }}
                      >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <ListItemIcon
                            style={{ minWidth: '20px', marginRight: '10px' }}
                          >
                            <img
                              style={{ maxWidth: '20px' }}
                              src={benefit.icon}
                              alt={benefit.title}
                            />
                          </ListItemIcon>
                          <Typography
                            variant="h3"
                            className={classes.benefitTitle}
                          >
                            {benefit.title}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          style={{ marginTop: '8px' }}
                        >
                          {benefit.description}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Collapse>
              <CardActions style={{ justifyContent: 'center' }} disableSpacing>
                <Typography
                  variant="body2"
                  onClick={handleExpandClick}
                  style={{
                    cursor: 'pointer',
                    color: '#1992f0',
                    fontWeight: '700',
                    paddingBottom: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {expanded ? 'Hide Benefits' : 'Show Benefits'}{' '}
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </Typography>
              </CardActions>
            </Box>
          </>
        )}
      </Card>
    </Container>
  )
}

export default PurchaseCard
