import { FormattedSKU, useAllSKUs } from '@talentinc/gatsby-theme-ecom/hooks/useSKUs'
import { useBrand } from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import useHandlebarsContext from '@talentinc/gatsby-theme-ecom/hooks/useHandlebarsContext'
import { insertHandlebarsTemplate } from '@talentinc/gatsby-theme-ecom/utils/insertHandlebarsTemplate'
import { TR, TCVUK, TCV } from './packages'
import { COPY } from './packages-copy'

export type PricePoint = {
  plan_code: string
  segment: string
  sku: FormattedSKU
  copy: {
    short_pitch: string
    very_short_pitch: string
    selling_points: string[]
    hover: {
      items: { title: string; description: string }[]
    }
  }
}

export const usePricePoints = (
  type: 'Job Search' | 'Resume Writing',
  segment?: string
) => {
  const handlebarsContext = useHandlebarsContext()
  const { site_id } = useBrand()
  const skus = useAllSKUs()

  const packages = siteId2packages(site_id)
  const packagesInSegment = packages[type].filter((i) => i.segment === segment)

  const packages2pricePoint = (p: { plan_code: string; copy: number }) => {
    const sku = skus.find((j) => j.plan_code === p.plan_code) as FormattedSKU

    let copy_items = sku.item_details
      .map((i) => COPY.find((j) => j.itemID === i.id))
      .filter(Boolean)
      // @ts-ignore
      .sort((a, b) => a?.order - b?.order)

    // NOTE: if itemID 18 (2 interviews ....) is present, remove the itemID 17 (1 interview ...)
    copy_items = copy_items.find((i) => i?.itemID === 18)
      ? copy_items.filter((i) => i?.itemID !== 17)
      : copy_items

    // NOTE: if itemID 21 (2 interviews ....) is present, remove the itemID 20 (1 interview ...)
    copy_items = copy_items.find((i) => i?.itemID === 21)
      ? copy_items.filter((i) => i?.itemID !== 20)
      : copy_items

    const copy = {
      short_pitch: insertHandlebarsTemplate(sku.short_pitch, handlebarsContext),
      very_short_pitch: insertHandlebarsTemplate(
        sku.very_short_pitch,
        handlebarsContext
      ),
      selling_points: copy_items
        .flatMap((i: any) => i.selling_points)
        .map((i) => insertHandlebarsTemplate(i, handlebarsContext)),
      hover: {
        items: copy_items.flatMap((i: any) =>
          i.hover_points.map((j: any) => ({
            title: insertHandlebarsTemplate(j.title, handlebarsContext),
            description: insertHandlebarsTemplate(j.description, handlebarsContext),
          }))
        ),
      },
    }

    return { ...p, segment, sku, copy } as PricePoint
  }

  return packagesInSegment.length <= 0
    ? {
        match: 'unknown',
        pricePoints:
          packages[type]
            ?.filter((i: any) => i.segment === 'unknown')
            ?.map(packages2pricePoint) ?? [],
      }
    : {
        match: segment,
        pricePoints: packagesInSegment.map(packages2pricePoint),
      }
}

const siteId2packages = (siteId?: number) => {
  switch (siteId) {
    case 1:
      return TR
    case 126:
      return TCVUK
    case 127:
      return TCV
    default:
      throw new Error('Unknown siteID ' + siteId)
  }
}
