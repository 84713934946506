import { useBETelemetry } from './useTelemetry'

export default function () {
  const telemetry = useBETelemetry()

  return {
    track: (properties: { cta_text: string; cta_link: string }) => {
      telemetry.track({
        event: 'click_homepage_cta_button',
        properties,
      })
    },
  }
}
