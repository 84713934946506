import React from 'react'
import { Box, Container } from '@mui/material'
import { BLOCKS } from '@contentful/rich-text-types'
import NavigationGroupLink from '../NavigationGroup/NavigationGroupLink'
import RichText from '../RichText'
import Testimonial from '../Testimonials/Testimonial'
import {
  PackageVariant,
  ProductPackageSection as ProductPackageSectionType,
} from '../../types/productPageV2'
import {
  PurchaseCardWithoutSku,
  PurchaseCardWithSku,
} from './NewProductPackageContainerV3'
import formatContentfulClasses from '../../utils/customClasses'
import { withHideOn } from '../../hocs/withHideOn'
import { usePricePageStyles } from './styles'

export interface ProductPackageSectionProps {
  packageSection: ProductPackageSectionType
  sectionClass?: string
  inCarousel?: boolean
}

const NewProductPackageSectionV3: React.FC<ProductPackageSectionProps> = ({
  packageSection,
  inCarousel = false,
}) => {
  const { classes, cx } = usePricePageStyles()

  if (packageSection) {
    return (
      <Box
        className={cx(
          {
            [classes.boxSection]: true,
            [classes.boxExpanded]:
              packageSection?.packageCard?.variant ===
              PackageVariant.expandedPackage,
          },
          ...formatContentfulClasses(packageSection.codeClasses)
        )}
      >
        <Box
          className={cx({
            [classes.background]:
              packageSection?.packageCard?.variant !==
              PackageVariant.expandedPackage,
            [classes.backgroundExpanded]:
              packageSection?.packageCard?.variant ===
              PackageVariant.expandedPackage,
          })}
        />
        <Box
          className={cx({
            [classes.textBox]: true,
            [classes.textBoxExpanded]:
              packageSection?.packageCard?.variant ===
              PackageVariant.expandedPackage,
          })}
        >
          {packageSection.testimonial && (
            <Testimonial
              quoteClass={cx({
                [classes.quote]: true,
                [classes.quoteInCarousel]: inCarousel,
              })}
              testimonial={packageSection.testimonial}
            />
          )}
          {packageSection.text && (
            <RichText
              rawBody={packageSection.text.raw}
              options={{
                props: {
                  [BLOCKS.HEADING_3]: {
                    className: classes.richTextH3,
                  },
                  [BLOCKS.HR]: {
                    className: classes.richTextHR,
                  },
                },
              }}
            />
          )}
          {packageSection.button && (
            <NavigationGroupLink
              className={classes.button}
              link={packageSection.button}
            />
          )}
        </Box>
        {packageSection.packageCard && (
          <Box
            className={cx({
              [classes.packageCard]: true,
              [classes.packageCardSingle]:
                packageSection.packageCard.variant !==
                PackageVariant.expandedPackage,
              [classes.packageCardExpanded]:
                packageSection.packageCard.variant ===
                PackageVariant.expandedPackage,
            })}
          >
            <Container disableGutters key={packageSection.contentful_id}>
              {packageSection.packageCard.variant === PackageVariant.free ||
              !packageSection.packageCard ? (
                <PurchaseCardWithoutSku
                  productPackageDetail={packageSection.packageCard}
                />
              ) : (
                <PurchaseCardWithSku
                  productPackageDetail={packageSection.packageCard}
                />
              )}
            </Container>
          </Box>
        )}
      </Box>
    )
  } else {
    return null
  }
}

export default withHideOn(NewProductPackageSectionV3)
