import React, { useState, useEffect } from 'react'
import { Grid, Box, Container, Tabs, Tab } from '@mui/material'
import { usePricePageStyles } from './styles'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { ProductPackagesSection as ProductPackagesSectionType } from '../../types/productPageV2'
import formatContentfulClasses from '../../utils/customClasses'
import RichText from '../RichText'
import { BindToFields } from '../../types/contentfulAttributes'
import { createContentfulClassGetter } from '../../utils/contentful'
import useHide from '../../hooks/useHide'
import PackageModal from './PackageModal'
import * as logger from '../../utils/logging'
import {
  useMixpanelCTAClickTracking,
  useMpEventTrackOn,
} from '../../utils/mixpanel'
import { MixpanelEventTypes, MixpanelEvents } from '../../types/mixpanel'
import { useBETelemetry } from '../../hooks/useTelemetry'
import NewProductPackagesContainer from './NewProductPackageContainerV5'
import ProductGroupPageSEO from '../SEO/ProductGroupSEO'

const HEROSECTION_IMAGE_JOB_SEARCH =
  'https://images.ctfassets.net/7thvzrs93dvf/1B0U1uZASvq7pFNOHaMyHL/64ea507048541e4cc815fb7af1dd0157/herosectiongirl.png'
const HEROSECTION_IMAGE_RESUME_WRITING =
  'https://images.ctfassets.net/7thvzrs93dvf/5VFYtXFpdRTxoMIArPYzOh/4a618b5a9b1203a146ff4db035591775/resume_writing_herosectionguy.png'

export interface ProductPackagesSectionProps {
  packagesSection: ProductPackagesSectionType
  sectionClass?: string
}

const NewProductPackagesSectionV5: React.FC<ProductPackagesSectionProps> = ({
  packagesSection,
  sectionClass,
}) => {
  const telemetry = useBETelemetry()

  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const jobSearchEventTrackOn = useMpEventTrackOn(
    MixpanelEventTypes.JobSearchEvent
  )
  const { classes, cx } = usePricePageStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0) // Default to Resume Writing tab

  useEffect(() => {
    const queryParams = new URLSearchParams(window?.location?.search)
    const tabParam = queryParams?.get('tab') || ''
    const shouldHideModal = queryParams?.has('hide_modal') // Check if 'hide_modal' query parameter is present
    const hasSeenModal = localStorage.getItem('hasSeenModal') // Check if the modal has been seen

    if (tabParam === 'job_search') {
      setSelectedTab(1) // Open the Job Search Services tab
      setModalOpen(false) // Hide modal if 'tab=job_search' is present
    } else {
      if (!shouldHideModal && !hasSeenModal) {
        setModalOpen(true) // Open modal if 'hide_modal' is NOT present and it hasn't been seen
      } else {
        setModalOpen(false) // Ensure modal is closed if 'hide_modal' is present or it has been seen
      }
    }
  }, [])

  useEffect(() => {
    const setCSSvar = (key: string, value: string) => {
      const root = document.documentElement
      root.style.setProperty(key, value)
    }

    if (selectedTab === 0) {
      setCSSvar('--section-resumewriting-display', 'flex')
      setCSSvar('--section-jobsearch-display', 'none')

      telemetry.track({ event: 'click_resume_writing' })
      handleMpCTAClickTracking('Resume Writing', '', {
        eventName: MixpanelEvents.ResumeWritingToggleClick,
        trackingData: {
          event_type: MixpanelEventTypes.JobSearchEvent,
        },
        trackOn: jobSearchEventTrackOn,
      })
    } else if (selectedTab === 1) {
      setCSSvar('--section-resumewriting-display', 'none')
      setCSSvar('--section-jobsearch-display', 'flex')

      telemetry.track({ event: 'click_job_search_services' })
      handleMpCTAClickTracking('Job Search', '', {
        eventName: MixpanelEvents.JobSearchToggleClick,
        trackingData: {
          event_type: MixpanelEventTypes.JobSearchEvent,
        },
        trackOn: jobSearchEventTrackOn,
      })
    } else {
      logger.error(`Unexpected tab value ${selectedTab}`)
    }
  }, [selectedTab, telemetry, handleMpCTAClickTracking, jobSearchEventTrackOn])

  const handleModalClose = () => {
    setModalOpen(false)
    localStorage.setItem('hasSeenModal', 'true') // Set that the modal has been seen.
  }

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setSelectedTab(newValue)
  }

  const applyContentfulStyles = createContentfulClassGetter(packagesSection.css)
  const hideClasses = useHide(packagesSection.hideOn)

  const displaySectionText =
    selectedTab === 1
      ? packagesSection.sectionText
      : packagesSection.newSectionText
  const displayProductPackageRows =
    selectedTab === 1
      ? packagesSection.productPackageRows ?? []
      : packagesSection.newProductPackagesRows ?? []

  const heroImage =
    selectedTab === 1
      ? HEROSECTION_IMAGE_RESUME_WRITING
      : HEROSECTION_IMAGE_JOB_SEARCH

  return (
    <>
      {modalOpen && (
        <PackageModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleShowOriginalPackages={() => setSelectedTab(0)}
          handleShowNewPackages={() => setSelectedTab(1)}
        />
      )}
      <ProductGroupPageSEO productPackageRows={displayProductPackageRows} />
      <Box
        className={cx(
          classes.box,
          sectionClass,
          ...formatContentfulClasses(packagesSection.codeClasses),
          hideClasses
        )}
      >
        <Container>
          <Box display="flex" justifyContent="center" mt={1} mb={1}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              classes={{ indicator: classes.tabsIndicator }}
              aria-label="product package tabs"
            >
              <Tab
                label="Resume Writing"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Job Search Services"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </Box>
          <Grid className={classes.bgHeroNew} container spacing={3}>
            {displaySectionText && (
              <Grid item className={classes.bgMobileLeft} xs={12} md={9}>
                <Box
                  className={cx(
                    classes.textBoxSection,
                    applyContentfulStyles(
                      BindToFields.SectionTextProductPackagesSection
                    )
                  )}
                >
                  <RichText
                    cssEntries={packagesSection.css}
                    rawBody={displaySectionText.raw}
                    references={displaySectionText.references}
                  />
                </Box>
              </Grid>
            )}
            <Grid item className={classes.hideImage} xs={12} md={3}>
              <Box>
                  <img src={heroImage} alt="Hero Section" />
              </Box>
            </Grid>
          </Grid>
        </Container>
        {displayProductPackageRows && (
          <Box className={(classes.cardsBox, classes.bgMobileRight)}>
            <Box className={cx({ [classes.resumeWriting]: selectedTab === 0 })}>
              <NewProductPackagesContainer
                productPackageRows={displayProductPackageRows}
                css={packagesSection.css}
              />
            </Box>
            {packagesSection.cta && (
              <Box
                className={cx(
                  classes.ctaContainer,
                  applyContentfulStyles(BindToFields.WrapperCTA)
                )}
              >
                <RichText
                  cssEntries={packagesSection.css}
                  rawBody={packagesSection.cta.raw}
                  references={packagesSection.cta.references}
                  options={{
                    props: {
                      [INLINES.ENTRY_HYPERLINK]: {
                        className: classes.cta,
                      },
                      [BLOCKS.HEADING_1]: {
                        className: applyContentfulStyles(
                          BindToFields.RichTextH1
                        ),
                      },
                      [BLOCKS.PARAGRAPH]: {
                        className: applyContentfulStyles(
                          BindToFields.ParagraphCTA
                        ),
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default NewProductPackagesSectionV5
