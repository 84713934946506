import { makeStyles } from 'tss-react/mui'
import herosectionnewpagebg from '../../images/herosectionnewpagebg.png'
import bgmobileheroright from '../../images/bgimagesmobilehero_right.png'
import bgmobileheroleft from '../../images/bgimagesmobilehero_left.png'

export const usePricePageStyles = makeStyles<
  void,
  'priceCard' | 'hoverContainer' | 'priceCardContent' | 'tabSelected'
>()((theme, _, classes) => ({
  cards4: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    gap: '2rem',
    paddingTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingTop: '3rem',
      width: '90%',
      margin: 'auto',
    },
  },
  cardsZipjob: {
    '&&': { alignItems: 'flex-end' },
  },
  everythingYouNeedToFind: {
    padding: '50px',
  },
  cards: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    gap: '2rem',
    paddingTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingTop: '3rem', // width: '85%',
      margin: 'auto',
    },
  },
  container: {
    margin: '0',
    maxWidth: '21.875rem',
    boxShadow: '0 9px 20px -8px rgb(32 33 36 / 30%)',
    flex: 1,

    [theme.breakpoints.down('md')]: {
      maxWidth: '18.25em',
      width: '32%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
      width: '100%',
    },
  },
  containerExpanded: {
    maxWidth: '100%',
    width: '31.75em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  background: {
    display: 'none',
  },
  backgroundExpanded: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    position: 'absolute',
    width: '78.9%',
    zIndex: -1,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      width: '70%',
    },
  },
  boxSection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  boxExpanded: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  button: {
    marginTop: '2em',
  },

  packageCard: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: 'calc(6% + 6.69em)',

    [theme.breakpoints.down('md')]: {
      marginRight: 'calc(6% + 2em)',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
      width: 'calc(100% - 12.3%)',
    },
    '@media (min-width: 1456px)': {
      marginRight: 'calc((100% - 91rem)/2 + 5.5rem + 6.69em)',
    },
  },
  packageCardSingle: {
    width: '18.25em',
  },
  packageCardExpanded: {
    maxWidth: '30%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '45%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4em',
      maxWidth: 'unset',
    },
  },
  quote: {
    marginBottom: '1rem',
    maxWidth: '150%',
    width: '38.44em',

    [theme.breakpoints.down('md')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      width: 'unset',

      '& h2': {
        fontSize: '1.81em',
      },
    },
  },
  quoteInCarousel: {
    minHeight: '14.5em',

    [theme.breakpoints.down('md')]: {
      minHeight: '17em',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '15.5em',
    },
  },
  richTextH3: {
    color: theme.palette.primary.main,
  },
  richTextHR: {
    margin: '1.5em 0',
    width: '100%',
  },
  textBox: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40%',
    padding: '4em 0 7.63em calc(6% + 6.93em)',
    width: '37.3em',

    [theme.breakpoints.down('md')]: {
      padding: '4em 0 7.63em calc(6% + 2em)',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: '-13.5em',
      maxWidth: 'unset',
      padding: '16.13em 6% 6em 6%',
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      maxWidth: '100%',
      padding: '4em 0 7.63em calc((100% - 91rem)/2 + 5.5rem + 2em)',
      width: 'calc((100% - 91rem)/2 + 37.3em)',
    },
  },
  textBoxExpanded: {
    backgroundColor: 'unset',
    margin: '4em 0 7.63em calc(6% + 6.93em)',
    padding: '0',
    maxWidth: '45%',
    width: '25em',

    [theme.breakpoints.down('md')]: {
      margin: '4em 0 4.68em 6%',
      maxWidth: '37%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      maxWidth: 'unset',
      padding: '4em 6% 4.68em 6%',
      textAlign: 'center',
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      margin: '4em 0 4.68em calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  bgHeroNew: {
    marginTop: '4rem',
    marginbottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginbottom: '0rem',
      background: 'none',
      paddingLeft: '24px!important',
      paddingRight: '24px!important',
      marginTop: '0rem',
    },
    backgroundImage: `url(${herosectionnewpagebg})`, // Add this line to set the background image
    backgroundSize: '80% 95%',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
  bgMobileRight: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-2rem',
        left: '-1rem',
        width: '52%',
        height: '7%',
        backgroundSize: 'auto',
        backgroundImage: `url(${bgmobileheroright})`,
        backgroundRepeat: 'no-repeat',
        transformOrigin: 'left',
        zIndex: '1',
      },
    },
  },

  bgMobileLeft: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '17.5rem',
        right: '-5.5rem',
        width: '60%',
        height: '60%',
        backgroundImage: `url(${bgmobileheroleft})`,
        backgroundRepeat: 'no-repeat',
        transformOrigin: 'right',
        zIndex: '1',
      },
    },
  },
  hideImage: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
    '& img': {
      position: 'relative',
      width: '125%',
      left: '-5rem',
      '@media (min-width: 769px) and (max-width: 1024px)': {
        width: '120%!important',
        top: '5rem!important',
        left: '-3.5rem!important',
      },
    },
  },
  box: {
    alignItems: 'center', // @ts-ignore // backgroundColor: ({ backgroundColor }) => //   getColor(backgroundColor, theme), // @ts-ignore // color: ({ backgroundColor }) => getTextColor(backgroundColor, theme),
    backgroundColor: '#eaf6ff',

    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2rem',
    borderRadius: '32px',
    paddingTop: '4rem !important',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '2rem',
      paddingTop: '2rem!important',
    },
  },
  heroContainer: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: '6rem',
    },
  },
  cardsContainer: {
    '&&': {
      paddingTop: '2rem',
      paddingBottom: '2rem',
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '1rem',
        paddingTop: '0rem',
      },
    },
  },
  cardsBox: {
    width: '100%',
  },
  liftUp: {
    [theme.breakpoints.up('md')]: {
      marginTop: '-7rem',
    },
  },
  textBoxSection: {
    marginBottom: '2.625rem',
    maxWidth: '72%',
    textAlign: 'left',
    '& button': {
      width: '25%',
    },
    [theme.breakpoints.down(1024)]: {
      maxWidth: '84%',
    },

    [theme.breakpoints.down(769)]: {
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0em',
      marginTop: '2em',
      '& h2': {
        fontSize: '17px',
      },
      '& h1': {
        fontSize: '2.64em',
      },
      '& button': {
        width: '100%',
        display: 'none',
      },
    },
    [theme.breakpoints.down(600)]: {
      '& h6': {
        paddingBottom: '0rem',
        position: 'relative',
        zIndex: '3',
        marginTop: '2rem',
      },
    },
  },
  cta: {
    textDecoration: 'underline',
    display: 'inline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ctaContainer: {
    margin: '0rem auto 0',
    borderRadius: '6px',
    background: 'rgba(255, 255, 255, 0.15)',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingTop: '2rem!important',
      paddingRight: '2rem!important',
      paddingLeft: '2rem!important',
      paddingBottom: '1.5rem!important', // width: '85%',
      margin: 'auto',
    },
  },
  originalPrice: {
    '&&': {
      position: 'relative',
      color: '#d90b23',
      marginLeft: '1rem',
      bottom: '0.15rem',
      fontSize: '20px',
      [theme.breakpoints.down('xs')]: {
        bottom: '0rem',
      },

      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: '#d90b23',
        width: '110%',
        position: 'absolute',
        top: '72%',
        left: '50%',
        height: '2px',
        marginLeft: '-55%',
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: '1rem',
        '&::after': {
          top: '55%!important',
        },
      },
    },
  },
  linkPrice: {
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:active': {
        textDecoration: 'none',
        color: 'inherit',
      },
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  },
  hoverContainer: {
    display: 'none',
    position: 'absolute',
    top: '70%' /* Default top position */,
    left: '-20px',
    width: 'calc(100% + 120%)' /* Default width */,
    height: 'auto',
    backgroundColor: 'white',
    padding: '16px',
    boxSizing: 'border-box',
    zIndex: 10,
    transition: 'opacity 0.3s ease',
    borderRadius: '24px' /* Adjust border radius to exclude top corners */,
    boxShadow:
      '0px 16px 64px -8px rgba(15, 56, 113, 0.18), 0px 6px 8px -2px rgba(15, 56, 113, 0.02)' /* Apply shadows */,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-80px' /* Default top position for triangle */,
      right: '20%' /* Default right position */,
      transform: 'translateX(50%)',
      borderWidth: '40px' /* Default triangle size */,
      borderStyle: 'solid',
      borderColor: 'transparent transparent rgb(249 249 249) transparent',
      zIndex: 11, // Above the hoverContainer's box shadow but below the triangle
    },
    '&::before': {
      // Shadow element
      content: '""',
      position: 'absolute',
      top: '-85px', // Slightly higher than the triangle
      right: '20%',
      transform: 'translateX(50%) rotateX(180deg)',
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '40px 50px 0 50px',
      borderColor: 'rgba(0, 0, 0, 0.2) transparent transparent transparent',
      filter: 'blur(25px)', // Adjust for desired softness
      zindex: 12,
    },
    '& a': {
      width: '100%',
      justifyContent: 'right',
    },
    '& h6': {
      textAlign: 'left',
      paddingLeft: '4%',
    },
    '& h3': {
      fontWeight: 'normal!important' as 'normal',
    },
    '& h4': {
      fontWeight: 'bold!important' as 'bold',
    },
  },

  hoverContainerFundamentals: {
    top: '78%' /* Adjust as needed */,
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '75%' /* Adjust as needed */,
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '75%' /* Adjust as needed */,
    },
  },
  hoverContainerStandard: {
    top: '78%' /* Adjust as needed */,
    left: '-93%',
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '30%' /* Adjust as needed */,
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '30%' /* Adjust as needed */,
    },
  },
  hoverContainerEnhanced: {
    top: '78%' /* Adjust as needed */,
    left: '-100%',
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '25%' /* Adjust as needed */,
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '25%' /* Adjust as needed */,
    },
  },
  hoverContainerFoundation: {
    top: '78%' /* Adjust as needed */,
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '75%' /* Adjust as needed */,
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '75%' /* Adjust as needed */,
    },
  },
  hoverContainerCore: {
    top: '78%' /* Adjust as needed */,
    left: '-93%',
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '30%' /* Adjust as needed */,
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '30%' /* Adjust as needed */,
    },
  },
  hoverContainerPlus: {
    top: '78%' /* Adjust as needed */,
    left: '-100%',
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '25%' /* Adjust as needed */,
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '25%' /* Adjust as needed */,
    },
  },
  hoverContainerPremier: {
    width: '60%',
    top: '88%',
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '75%',
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '75%' /* Adjust as needed */,
    },
  },
  hoverContainerSignature: {
    width: '60%',
    top: '88%',
    '&::after': {
      top: '-80px' /* Default top position for triangle */,
      right: '75%',
    },
    '&::before': {
      top: '-60px' /* Default top position for triangle */,
      right: '75%' /* Adjust as needed */,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandText: {
    marginRight: theme.spacing(1),
  },
  benefitTitle: {
    fontWeight: 400,
    fontSize: '19px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '19px',
    },
  },
  resumeWriting: {
    [`& .${classes.priceCard}`]: {
      minHeight: '635px',
    },

    [`& .${classes.priceCard}:last-child`]: {
      minHeight: '200px !important',
    },
  },
  priceCard: {
    marginBottom: '2.5rem',
    marginTop: 0,
    background: '#fff',
    borderRadius: '24px',
    textAlign: 'center',
    padding: '0rem!important',
    minHeight: '610px',
    position: 'relative',
    overflow: 'visible',
    backgroundColor: '#fefeff',
    boxShadow:
      '0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08)',
    [`&:hover .${classes.hoverContainer}`]: {
      display: 'block',
    },
    [`&:hover .${classes.priceCardContent}:hover ~ .${classes.hoverContainer}`]:
      {
        display: 'none!important' as 'none',
      },
    '@media (max-width: 600px)': {
      marginBottom: '0.5rem!important',
      padding: '0px!important',
      borderRadius: '16px',
      boxShadow: 'none',
      border: '1px solid #E7EAF4',
      minHeight: '20px!important',
      position: 'relative',
      zIndex: '3',
      [`&:hover .${classes.hoverContainer}`]: {
        display: 'none',
      },
    },
    '@media (max-width: 1024px)': {
      minHeight: 'unset!important' as 'unset',
      [`&:hover ${classes.hoverContainer}`]: {
        display: 'none!important' as 'none',
      },
    },
  },
  hoverContainerContent: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  hoverContainerColumn: {
    flex: '1',
    '&:first-child': {
      marginRight: '16px',
    },
  },

  priceCardContent: {
    padding: '24px 32px 8px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0.5rem !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },

  priceFeaturesList: {
    paddingTop: 0,
    paddingBottom: '0px!important',
    minHeight: '245px',
    [theme.breakpoints.down('md')]: {
      padding: '0px!important',
      minHeight: '400px!important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px!important',
      minHeight: 'unset!important' as 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px!important',
      minHeight: 'unset!important' as 'unset',
    },
  },
  grayFeature: {
    color: '#818B9E',
  },
  priceMobileFeatures: {
    borderTop: '1px solid #f5f5f5',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px!important',
    },
  },
  priceMobileFeaturesFont: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    textAlign: 'left',
  },

  priceListFeatures: {
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    paddingTop: 0,
    paddingBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '4px!important',
      paddingRight: '4px!important',
    },
  },
  priceCardTitle: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    paddingTop: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px!important',
      fontWeight: 400,
      textAlign: 'left',
    },
  },
  titleAndPriceContainer: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #E7EAF4',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '4px',
      marginBottom: '8px',
      '& .affirm-as-low-as': {
        fontSize: '10px!important',
      },
    },
  },

  priceCardSubtitle: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    display: 'block',
    color: '#656E83',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px!important',
      textAlign: 'left',
      display: 'none',
    },
  },
  priceCardSubtitleMobile: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    display: 'none',
    color: '#656E83',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px!important',
      textAlign: 'left',
      display: 'block',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  priceCardMonthlyPrice: {
    fontSize: '16px',
    fontWeight: 400,
    color: 'gray',
    paddingBottom: '8px',
  },
  priceCardDescription: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#5A6377',
    paddingTop: '0.625px',
    paddingLeft: '22px',
    paddingRight: '22px',
    minHeight: '60px',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      minHeight: '65px',
      display: 'none',
    },
  },
  priceCardPrice: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '42px',
    marginBottom: '0px',
    paddingTop: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4px!important',
      textAlign: 'right',
      fontSize: '24px',
      fontWeight: 400,
    },
  },
  priceCardFeature: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0!important',
    },
  },
  oneTimePayment: {
    width: 'auto',
  },
  noRightPadding: {
    paddingBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      paddingTop: '0px',
      paddingLeft: '0px!important',
      paddingRight: '0px!important',
    },
  },

  affirmLogo: {
    minHeight: '2rem',
    color: 'black!important' as 'black', // @TODO: Add to the TopResumeTheme
  },
  threeColPackage: {
    '&&': {
      padding: '12px',
      [theme.breakpoints.down('md')]: {
        padding: '4px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  },
  cardDarkPrimary: {
    backgroundColor: 'transparent',
    color: theme.palette.background.default,
  },
  cardPopular: {
    position: 'relative',
    overflow: 'visible',
  },
  contentMostPopular: {
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '0 0 6px 6px',
  },
  borderRadius: {
    borderRadius: theme.shape.borderRadius,
  },

  contentExpanded: {
    backgroundColor: theme.palette.background.default,
    minHeight: 'unset',
  },
  description: {
    fontSize: '1.0625rem',
    fontWeight: 700,
    margin: '.516rem 0 2.3rem',
    color: '#7a8498', // @TODO: Add to the TopResumeTheme
    textAlign: 'center',
  },

  banner: {
    backgroundColor: '#e7e7',
    color: theme.palette.background.default,
    lineHeight: 'normal',
    textAlign: 'center',
  },
  hideOnSmall: {
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
  hideOnSmall3Col: {
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  popularBanner: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '6px 6px 0 0',
    '&&': {
      // Increase the specificity to make sure that the display: 'none' doesn't affect
      display: 'block',
    },
  },
  taggedPopularBanner: {
    top: '0rem',
    lineHeight: '0.5rem',
    boxSizing: 'inherit',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    left: '30%',
    padding: '0.7rem 1rem 0.5rem',
    margin: '0',
    letterSpacing: '.1em',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#52b37f',
    borderRadius: '0px 0px 12px 12px',
    zIndex: 3,
    fontFamily: 'proxima-nova,Helvetica,Arial,sans-serif',
    [theme.breakpoints.down('md')]: {
      top: '0rem',
      left: '35%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 0.6rem 0.3rem',
      top: '0rem',
      left: '28%',
    },
  },

  secondaryCta: {
    fontWeight: 600,
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  highlightedContainer: {
    paddingTop: '3rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '4.5rem',
    },
  },
  lowerContainer: {
    textAlign: 'center',
    padding: '1rem 0rem 1rem 0rem',
  },
  splitPayment: {
    display: 'inline-block',
    fontSize: '0.825rem',
    lineHeight: '1.25rem',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& span': {
      fontSize: '2rem',
      fontWeight: 500,
    },
    '&&': {
      marginBlock: '0.5em',
    },
  },
  gridItem: {
    width: '100%', // Default to full width
    [theme.breakpoints.up('md')]: {
      width: '33.33%!important', // Adjust width on medium and larger screens
    },
  },
  responsiveGrid: {
    // For mobile devices, display the carousel
    display: 'block',
    opacity: 0, // Start with the component hidden
    [theme.breakpoints.up('md')]: {
      display: 'none', // Hide on desktop
    },
  },
  noMinHeight: {
    minHeight: 'unset', // remove minHeight for elite
  },
  fullWidth: {
    width: '100%',
  },
  premierContainer: {
    // Additional styles for the elite package container if needed
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: '#f4f4f4', // example style
  },
  premierCard: {
    // Additional styles specific to the elite package card
    backgroundColor: '#fff', // example style
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  premierContentLeft: {
    // Styles for the left side content
    flex: '1 1 50%',
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    '& p': {
      paddingLeft: '0px!important',
      paddingTop: '12px',
    },
    '& h3': {
      display: 'none!important' as 'none',
    },
  },
  premierContentRight: {
    // Styles for the right side content
    // flex: '1 1 50%',
    paddingLeft: theme.spacing(2),
    display: 'flex',
    '&& > div': {
      textAlign: 'right!important' as 'right',
      paddingRight: '2rem!important',
    },
  },
  premierContent: {
    // Additional styles specific to the elite package content
    padding: theme.spacing(2),
  },
  priceCardFeatureDescription: {
    width: '100%',
  },
  benefitDesc: {
    fontSize: '16px',
    color: '#656E83',
  },
  responsiveContainer: {
    display: 'none', // Hide by defaul
    [theme.breakpoints.up('md')]: {
      display: 'flex', // Show only on desktop
    },
  },
  benefitsTitle: {
    position: 'absolute',
    top: '-20px',
    right: '10px',
    backgroundColor: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontSize: '23px',
  },
  buttonContainer: {
    margin: 'auto',
  },
  tabsRoot: {
    width: '120%', // Ensure tabs take up full width
    borderBottom: '1px solid gray', // Gray border for the tabs container
  },
  tabRoot: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid gray', // Gray underline for all tabs
    color: 'black',
    paddingLeft: '34px',
    paddingRight: '34px',
    margin: '0', // Remove margins to avoid space between tabs
    cursor: 'pointer',
    outline: 'none',
    flexGrow: 1, // Make sure tabs are evenly spaced
    textTransform: 'none', // No text transformation
    fontSize: '22px', // Default font size
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px', // Font size for mobile
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    [`&.${classes.tabSelected}`]: {
      borderBottom: '3px solid #1992f0', // Blue underline for selected tab
      color: 'black',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      outline: 'none', // Remove focus outlin
    },
  },
  tabSelected: {},
  tabsIndicator: {
    display: 'none', // Hide the default indicator
  },
}))
