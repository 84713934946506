import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CustomComponent from '../CustomComponent'
import RichText from '../RichText'
import { PackagesSection as PackagesSectionType } from '../../types/packages'
import useHide from '../../hooks/useHide'

export interface PackagesSectionProps {
  packagesSection: PackagesSectionType
  sectionClass?: string
}

const PackagesSection: React.FC<PackagesSectionProps> = ({
  packagesSection,
  sectionClass,
}) => {
  const { classes, cx } = useStyles()
  const hideClasses = useHide(packagesSection.hideOn)

  return (
    <Box className={cx(classes.box, sectionClass, hideClasses)}>
      {packagesSection.sectionText && (
        <Box className={classes.textBox}>
          <RichText rawBody={packagesSection.sectionText.raw} />
        </Box>
      )}
      {packagesSection.customComponent && (
        <Box className={classes.cardsBox}>
          <CustomComponent item={packagesSection.customComponent} />
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  box: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '4.53em',
    paddingTop: '4.19em',
  },
  cardsBox: {
    width: '100%',
  },
  textBox: {
    marginBottom: '3em',
    maxWidth: '46.63em',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      '& h2': {
        fontSize: '1.81em',
      },
      '& h1': {
        fontSize: '1.94em',
      },
    },
  },
}))

export default PackagesSection
