import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useLocation from 'react-use/lib/useLocation'
import Link from '../Link'
import useDiscountCode from '../../hooks/useDiscountCode'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import useDiscountToken from '../../hooks/useDiscountToken'
import {
  AlternatePlan,
  SnakeCaseToCamelCase,
  useAllSKUs,
  useSKU,
} from '../../hooks/useSKUs'
import { getCurrencySymbol } from '../../utils/currency'
import { urlPathJoin } from '../../utils/url'

type SplitPaymentProps = {
  [k in keyof AlternatePlan as SnakeCaseToCamelCase<k>]: NonNullable<
    AlternatePlan[k]
  >
}

const FILTERED_ELITE_PLAN_CODES = ['PCirvvG', '5BMTLL', 'rS2hf', 'H6CZDkB']

export function SplitPaymentLink({
  intervalTimes,
  planCode,
  amount,
}: SplitPaymentProps): React.JSX.Element | null {
  const { classes } = useStyles()
  const { t } = useTranslation('translation')

  const sku = useSKU([], planCode)
  const [skuFallback] = useAllSKUs()
  const location = useLocation()
  const discountToken = useDiscountToken()
  const { data } = useDiscountCode(planCode, discountToken)
  const { isTopCV, isTopCVuk } = useBrand()

  if ((isTopCV || isTopCVuk) && FILTERED_ELITE_PLAN_CODES.includes(planCode))
    return null

  if (data) {
    const url = urlPathJoin([`/purchase/${data.plan_code}`], {
      baseURL: location.origin,
      search: { dt: data.discount_token },
    })
    return (
      <Link
        className={classes.splitPayment}
        href={url}
        dangerouslySetInnerHTML={{
          __html: t('splitPaymentText', {
            intervalTimes,
            amount: data.sticker_price - data.discount_value,
            currency: data.currency_symbol,
          }),
        }}
      />
    )
  }

  return (
    <Link
      className={classes.splitPayment}
      href={`/purchase/${planCode}`}
      dangerouslySetInnerHTML={{
        __html: t('splitPaymentText', {
          intervalTimes,
          amount,
          currency: getCurrencySymbol(
            sku?.currency_code ?? skuFallback?.currency_code
          ),
        }),
      }}
    />
  )
}

const useStyles = makeStyles()(() => ({
  splitPayment: {
    display: 'inline-block',
    fontSize: '0.825rem',
    lineHeight: '1.25rem',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& span': {
      fontSize: '2rem',
      fontWeight: 500,
    },
    '&&': {
      marginBlock: '0.5em',
    },
  },
}))
