export const COPY = [
  {
    order: 1,
    itemID: 75,
    selling_points: [
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title: 'Professionally Written {{ titlecased brand.flagshipProduct }}',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 78,
    selling_points: [
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title: 'Professionally Written {{ titlecased brand.flagshipProduct }}',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 80,
    selling_points: [
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title: 'Professionally Written {{ titlecased brand.flagshipProduct }}',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 14,
    selling_points: [
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title: 'Professionally Written {{ titlecased brand.flagshipProduct }}',
        description:
          'Capture the attention of prestigious institutions with a {{ brand.flagshipProduct }} tailored to highlight your academic excellence.',
      },
    ],
  },
  {
    order: 1,
    itemID: 79,
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title:
          'Professionally Written {{ titlecased brand.flagshipProduct }} (Top 20%)',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 81,
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title:
          'Professionally Written {{ titlecased brand.flagshipProduct }} (Top 10%)',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 24,
    selling_points: [
      'Top 20% of Writer Network',
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title:
          'Professionally Written {{ titlecased brand.flagshipProduct }} (Top 20%)',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 25,
    selling_points: [
      'Top 10% of Writer Network',
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title:
          'Professionally Written {{ titlecased brand.flagshipProduct }} (Top 10%)',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 1,
    itemID: 90,
    selling_points: [
      '10-minute Video Review of your {{ titlecased brand.flagshipProduct }}',
    ],
    hover_points: [
      {
        title: '10-Minute Video',
        description:
          '10-minute video review from our professional {{ brand.flagshipProduct }} reviewers. Targeted feedback on your {{ brand.flagshipProduct }}, including areas to improve and actions to take.',
      },
    ],
  },
  {
    order: 2,
    itemID: 91,
    selling_points: [
      'Professionally Written {{ titlecased brand.flagshipProduct }} Draft',
    ],
    hover_points: [
      {
        title: 'Refreshed {{ titlecased brand.flagshipProduct }}',
        description:
          'Our experts will update your {{ brand.flagshipProduct }} and deliver it to you in a new Applicant Tracking System compliant template.',
      },
    ],
  },
  {
    order: 3,
    itemID: 11,
    selling_points: ['Cover Letter'],
    hover_points: [
      {
        title: 'Cover Letter ',
        description:
          'Employers are 40% more likely to read a {{ brand.flagshipProduct }} with a cover letter.',
      },
    ],
  },
  {
    order: 4,
    itemID: 15,
    selling_points: ['Linkedin Makeover'],
    hover_points: [
      {
        title: 'LinkedIn Makeover',
        description:
          'Our LinkedIn experts will review and rewrite your profile because 97% of employers use LinkedIn.',
      },
    ],
  },
  {
    order: 5,
    itemID: 100,
    selling_points: ['We Apply For You: 10 Applications'],
    hover_points: [
      {
        title: '10 Job Applications (we apply for you)',
        description:
          'Our specialists will complete/finish/apply/do/send off to up to 10 job applications for you within the first 30 days of your receiving your {{ brand.flagshipProduct }}.',
      },
    ],
  },
  {
    order: 5,
    itemID: 101,
    selling_points: ['We Apply For You: 20 Applications'],
    hover_points: [
      {
        title: '20 Job Applications (we apply for you)',
        description:
          'Our specialists will apply to up to 20 job applications for you within the first 30 days of you receiving your {{ brand.flagshipProduct }}.',
      },
    ],
  },
  {
    order: 5,
    itemID: 103,
    selling_points: ['We Apply For You: 40 Applications'],
    hover_points: [
      {
        title: '40 Job Applications (we apply for you)',
        description:
          'Our specialists will apply to up to 40 job applications for you within the first 30 days of you receiving your {{ brand.flagshipProduct }}.',
      },
    ],
  },
  {
    order: 5,
    itemID: 102,
    selling_points: ['We Apply For You: 50 Applications'],
    hover_points: [
      {
        title: '50 Job Applications (we apply for you)',
        description:
          'Our specialists will apply to up to 50 job applications for you within the first 30 days of you receiving your {{ brand.flagshipProduct }}.',
      },
    ],
  },
  {
    order: 5,
    itemID: 97,
    selling_points: ['We Apply For You: 20 Applications'],
    hover_points: [
      {
        title: '20 Job Applications (we apply for you) ',
        description:
          'Our specialists will apply to up to 20 job applications for you within the first 30 days of you receiving your {{ brand.flagshipProduct }}.',
      },
    ],
  },
  {
    order: 6,
    itemID: 98,
    selling_points: ['Recruiter Outreach'],
    hover_points: [
      {
        title: '{{ titlecased brand.flagshipProduct }} sent to recruiters',
        description:
          "We can send your {{ brand.flagshipProduct }} to 200 recruiters who are actively looking for candidates with your skills, whenever you're ready to start job hunting.",
      },
    ],
  },
  {
    order: 7,
    itemID: 17,
    selling_points: ['1 Interview Coaching Session'],
    hover_points: [
      {
        title: 'Interview Coaching Session',
        description:
          'Jobseekers who practice for interviews have a 96% higher success rate.',
      },
    ],
  },
  {
    order: 7,
    itemID: 18,
    selling_points: ['2 Interview Coaching Sessions'],
    hover_points: [
      {
        title: 'Interview Coaching Sessions',
        description:
          'Jobseekers who practice for interviews have a 96% higher success rate.',
      },
    ],
  },
  {
    order: 8,
    itemID: 86,
    selling_points: [
      'World-class {{ titlecased brand.flagshipProduct }} Builder',
      'Career Services Platform',
    ],
    hover_points: [
      {
        title: 'World-class {{ titlecased brand.flagshipProduct }} Builder',
        description:
          'Access to our {{ brand.flagshipProduct }} editor: Receive your updated {{ brand.flagshipProduct }} in our world-class {{ brand.flagshipProduct }} builder. Choose from up to 27 templates formatted for success.',
      },
      {
        title: 'Career Services Platform',
        description:
          'Tools like interview prep, salary analysis, recruiter outreach and more. Supercharge your job search with everything you need, all in one place.',
      },
    ],
  },
  {
    order: 9,
    itemID: 66,
    selling_points: ['60-day Interview Guarantee'],
    hover_points: [
      {
        title: '60-day Interview Guarantee',
        description:
          "If you're not getting twice as many interviews with your {{ brand.flagshipProduct }} we'll rewrite it one more time.",
      },
    ],
  },
  {
    order: 1,
    itemID: 22,
    selling_points: [
      'Professionally Written {{ titlecased brand.flagshipProduct }}',
      'Templates Optimized for ATS',
    ],
    hover_points: [
      {
        title: 'Professionally Written {{ titlecased brand.flagshipProduct }}',
        description:
          "Our {{ brand.flagshipProduct }}s are written by industry experts, formatted to catch employers' attention, and optimized for Application Tracking Systems (ATS).",
      },
    ],
  },
  {
    order: 7,
    itemID: 20,
    selling_points: ['1 Interview Coaching Sessions'],
    hover_points: [
      {
        title: 'Interview Coaching Sessions',
        description:
          'Jobseekers who practice for interviews have a 96% higher success rate.',
      },
    ],
  },
  {
    order: 7,
    itemID: 21,
    selling_points: ['2 Interview Coaching Sessions'],
    hover_points: [
      {
        title: 'Interview Coaching Sessions',
        description:
          'Jobseekers who practice for interviews have a 96% higher success rate.',
      },
    ],
  },
]
