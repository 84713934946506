import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles<{ pageKind: string }, 'grid'>()(
  (theme, { pageKind }, classes) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#eaf6ff',
      color: '#000',
      borderRadius: '0 0 32px 32px',
      padding: '24px',
      paddingTop: '16px',
      paddingBottom: '2rem',
      backgroundImage:
        pageKind === 'Job Search'
          ? 'url(https://images.ctfassets.net/7thvzrs93dvf/4SIxTqSl3BhJTOrlhLRPKM/cadfb571da6858e115f5f72faad86e12/background-hero-jss.jpg)'
          : 'url(https://images.ctfassets.net/7thvzrs93dvf/6uAyri84XvA1f59F72xnMR/eaf4ec8234768b1c20b756b31358b18a/background-hero.jpg)',
      backgroundPosition: 'right top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 700px',

      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },

      [theme.breakpoints.down('md')]: {
        padding: '0',

        [`& .${classes.grid}`]: {
          padding: '32px 8px 8px 8px',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'repeat(4, minmax(225px, max-content))',
          gridTemplateAreas: `
            "A"
            "B"
            "C"
            "D"
          `,
          gap: '32px',
        },
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: '2rem!important',
        paddingBottom: '2rem',
      },
    },

    grid: {
      scrollMarginTop: theme.spacing(8),
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(240px, 1fr))',
      gridTemplateRows: 'minmax(555px, max-content) minmax(555px, max-content)',
      gridTemplateAreas: `
        "A B C"
        "D D D"
      `,
      gap: '62px',
      paddingTop: '32px',

      '& > div:last-child': {
        gridArea: 'D',
      },
    },

    footer: {
      paddingTop: '40px',
      paddingBottom: '40px',
      textAlign: 'center',
    },
  })
)
