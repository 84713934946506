import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  price_wrapper: {
    display: 'flex',
    gap: '12px',
  },
  price_primary: {
    fontSize: '30px !important',
    fontWeight: 700,
    lineHeight: '42px',
  },
  price_secondary: {
    position: 'relative',
    color: '#d90b23',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '46px !important',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: '110%',
      height: '2px',
      backgroundColor: 'currentcolor',
    },
  },

  affirm_wrapper: {
    display: 'block',
    textAlign: 'center',

    '& span': {
      lineHeight: '1.5294',
      fontWeight: 400,
      fontSize: '1rem !important',
    },
  },
}))
