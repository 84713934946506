import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Carousel from '../LandingPage/Carousel'
import useBrand from '../../hooks/useBrand'

interface Props {
  children: React.ReactNode
}

const PricePackageCarousel: React.FC<Props> = ({ children }) => {
  const { classes, cx } = useStyles()
  const brand = useBrand()

  const responsiveSettings = [
    {
      breakpoint: 960,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 2.2,
        infinite: false,
        rtl: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        dots: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        infinite: false,
        rtl: false,
      },
    },
  ]

  return (
    <Box className={classes.carouselContainer}>
      <Typography variant="caption" className={classes.heading}>
        Browse Packages
      </Typography>
      <Box
        className={cx({
          [classes.carousel]: true,
          [classes.carouselZipJob]: brand.name === 'ZipJob',
        })}
      >
        <Carousel
          slidesToShow={3}
          dots={true}
          arrows={false}
          dotsClass={classes.breadcrumbs}
          autoplay={false}
          autoplaySpeed={4000}
          responsiveSettings={responsiveSettings}
        >
          {children}
        </Carousel>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#818B9E',
      width: '94%',
      paddingTop: '14px',
      borderTop: '1px solid #E7EAF4',
      letterSpacing: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#818B9E',
      width: '92%',
      paddingTop: '14px',
      borderTop: '1px solid #E7EAF4',
      letterSpacing: '2px',
    },
  },
  breadcrumbs: {
    '&&': {
      [theme.breakpoints.down('md')]: {
        bottom: '45.3em',
        right: '0.3em',
        width: '100px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '-6%',
        right: '0.3em',
        width: '100px',
      },
    },
  },

  carousel: {
    '& [tabindex]': {
      '&:focus': {
        outline: 'none !important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .slick-slide[data-index="2"].slick-active': {
        width: '27%!important',
      },
      '& .slick-slide[data-index="0"].slick-active': {
        width: '27%!important',
      },
      '& .slick-slide[data-index="1"].slick-active': {
        width: '27%!important',
      },
      '& .slick-slide[data-index="1"]': {
        width: '39%!important',
      },
    },

    width: '100%',
    overflow: 'visible!important',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: '100vw',
    },
  },

  carouselZipJob: {
    marginTop: '1.5rem',
  },
}))

export default PricePackageCarousel
