import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
})

export const usePricePageStyles = makeStyles()((theme) => ({
  cards4: {
    '&&': {
      display: 'grid',
      justifyItems: 'center',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      gap: '2rem',
      '&& > div': {
        margin: 0,
        [theme.breakpoints.up('md')]: {
          width: 'unset',
        },
      },
      '& > div > div': {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,

        '& > div': {
          flex: 1,
          justifyContent: 'space-between',
        },
      },
      '& > *': {
        height: '100%',
      },
      [theme.breakpoints.down('lg')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gridGap: '1.5rem',
        maxWidth: '68.36%',
        margin: '0 auto',
        '& > div:last-child, & > div:nth-last-child(2)': {
          '& > div > p': {
            display: 'none',
          },
        },
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down(768)]: {
        gridTemplateColumns: '1fr',
      },
    },
  },
  cardsZipjob: {
    '&&': { alignItems: 'flex-end' },
  },
  cards: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    gap: '2rem',
    minHeight: '350px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  container: {
    margin: '0',
    maxWidth: '21.875rem',
    boxShadow: '0 9px 20px -8px rgb(32 33 36 / 30%)',
    flex: 1,

    [theme.breakpoints.down('lg')]: {
      maxWidth: '18.25em',
      width: '32%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'initial',
      width: '100%',
    },
  },
  containerExpanded: {
    maxWidth: '100%',
    width: '31.75em',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  background: {
    display: 'none',
  },
  backgroundExpanded: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    position: 'absolute',
    width: '78.9%',
    zIndex: -1,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      width: '70%',
    },
  },
  boxSection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  boxExpanded: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  button: {
    marginTop: '2em',
  },

  packageCard: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginRight: 'calc(6% + 6.69em)',

    [theme.breakpoints.down('lg')]: {
      marginRight: 'calc(6% + 2em)',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '0',
      width: 'calc(100% - 12.3%)',
    },
    '@media (min-width: 1456px)': {
      marginRight: 'calc((100% - 91rem)/2 + 5.5rem + 6.69em)',
    },
  },
  packageCardSingle: {
    width: '18.25em',
  },
  packageCardExpanded: {
    maxWidth: '30%',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '45%',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '4em',
      maxWidth: 'unset',
    },
  },
  quote: {
    marginBottom: '1rem',
    maxWidth: '150%',
    width: '38.44em',

    [theme.breakpoints.down('lg')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      width: 'unset',

      '& h2': {
        fontSize: '1.81em',
      },
    },
  },
  quoteInCarousel: {
    minHeight: '14.5em',

    [theme.breakpoints.down('lg')]: {
      minHeight: '17em',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '15.5em',
    },
  },
  richTextH3: {
    color: theme.palette.primary.main,
  },
  richTextHR: {
    margin: '1.5em 0',
    width: '100%',
  },
  textBox: {
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40%',
    padding: '4em 0 7.63em calc(6% + 6.93em)',
    width: '37.3em',

    [theme.breakpoints.down('lg')]: {
      padding: '4em 0 7.63em calc(6% + 2em)',
    },
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      marginTop: '-13.5em',
      maxWidth: 'unset',
      padding: '16.13em 6% 6em 6%',
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      maxWidth: '100%',
      padding: '4em 0 7.63em calc((100% - 91rem)/2 + 5.5rem + 2em)',
      width: 'calc((100% - 91rem)/2 + 37.3em)',
    },
  },
  textBoxExpanded: {
    backgroundColor: 'unset',
    margin: '4em 0 7.63em calc(6% + 6.93em)',
    padding: '0',
    maxWidth: '45%',
    width: '25em',

    [theme.breakpoints.down('lg')]: {
      margin: '4em 0 4.68em 6%',
      maxWidth: '37%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0',
      maxWidth: 'unset',
      padding: '4em 6% 4.68em 6%',
      textAlign: 'center',
      width: '100%',
    },
    '@media (min-width: 1456px)': {
      margin: '4em 0 4.68em calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  box: {
    alignItems: 'center',
    // @ts-ignore
    // backgroundColor: ({ backgroundColor }) =>
    //   getColor(backgroundColor, theme),
    // @ts-ignore
    // color: ({ backgroundColor }) => getTextColor(backgroundColor, theme),
    backgroundColor: '#fafafc',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0rem',
    paddingTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
      paddingTop: '2rem',
    },
  },
  heroContainer: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: '6rem',
    },
  },
  cardsContainer: {
    '&&': {
      paddingTop: '2rem',
      paddingBottom: '2rem',
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '1rem',
        paddingTop: '0rem',
      },
    },
  },
  cardsBox: {
    width: '100%',
  },
  liftUp: {
    [theme.breakpoints.up('md')]: {
      marginTop: '-7rem',
    },
  },
  textBoxSection: {
    marginBottom: '2.625rem',
    maxWidth: '72%',
    textAlign: 'center',

    [theme.breakpoints.down(1024)]: {
      maxWidth: '84%',
    },

    [theme.breakpoints.down(769)]: {
      maxWidth: '100%',
      marginBottom: '1em',
      '& h2': {
        fontSize: '17px',
      },
      '& h1': {
        fontSize: '2.64em',
      },
    },
    [theme.breakpoints.down(600)]: {
      paddingRight: '1.5rem!important',
    },
  },
  cta: {
    textDecoration: 'underline',
    display: 'inline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ctaContainer: {
    margin: '0rem auto 0',
    borderRadius: '6px',
    background: 'rgba(255, 255, 255, 0.15)',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingTop: '1rem!important',
      paddingRight: '1.5rem!important',
    },
  },
  originalPrice: {
    '&&': {
      position: 'relative',
      color: theme.palette.secondary.dark,
      marginLeft: '1rem',
      bottom: '0rem',

      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: theme.palette.secondary.dark,
        width: '110%',
        position: 'absolute',
        top: '70%',
        left: '50%',
        height: '2px',
        marginLeft: '-55%',
      },

      [theme.breakpoints.down('md')]: {
        marginLeft: '1rem',
        '&::after': {
          top: '55%!important',
        },
      },
    },
  },
  linkPrice: {
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:active': {
        textDecoration: 'none',
        color: 'inherit',
      },
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  },
  priceCard: {
    marginBottom: '2.5rem',
    marginTop: 0,
    background: '#fff',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '0rem!important',
    boxShadow:
      '0px 8px 20px -4px rgba(15, 56, 113, 0.12), 0px 2px 4px -1px rgba(15, 56, 113, 0.08)',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem!important',
      padding: '0px!important',
    },
  },

  priceCardContent: {
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem 0.5rem !important',
    },
  },

  priceFeaturesList: {
    paddingTop: 0,
    paddingBottom: '0px!important',
    [theme.breakpoints.down('lg')]: {
      padding: '0px!important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px!important',
    },
  },
  grayFeature: {
    color: '#818B9E',
  },
  priceMobileFeatures: {
    borderTop: '1px solid #f5f5f5',
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px!important',
    },
  },
  priceMobileFeaturesFont: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '1rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    textAlign: 'left',
  },
  priceListFeatures: {
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    paddingTop: 0,
    paddingBottom: '12px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '4px!important',
      paddingRight: '4px!important',
    },
  },
  priceCardTitle: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px!important',
    },
  },
  priceCardSubtitle: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '#202124',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px!important',
    },
  },
  priceCardMonthlyPrice: {
    fontSize: '16px',
    fontWeight: 400,
    color: 'gray',
    paddingBottom: '8px',
  },
  priceCardDescription: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#5A6377',
    paddingTop: '0.625px',
    [theme.breakpoints.down('md')]: {
      minHeight: '65px',
    },
  },
  priceCardPrice: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '42px',
    marginBottom: '0px',
    paddingTop: '24px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '4px!important',
    },
  },
  priceCardFeature: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      padding: '0!important',
    },
  },
  oneTimePayment: {
    width: 'auto',
  },
  noRightPadding: {
    paddingBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      paddingTop: '0px',
      paddingLeft: '0px',
      paddingRight: '16px',
    },
  },

  affirmLogo: {
    minHeight: '2rem',
    color: 'black!important', // @TODO: Add to the TopResumeTheme
  },
  threeColPackage: {
    '&&': {
      padding: '12px',
      [theme.breakpoints.down('md')]: {
        padding: '4px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  },
  cardDarkPrimary: {
    backgroundColor: 'transparent',
    color: theme.palette.background.default,
  },
  cardPopular: {
    position: 'relative',
    overflow: 'visible',
  },
  contentMostPopular: {
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '0 0 6px 6px',
  },
  borderRadius: {
    borderRadius: theme.shape.borderRadius,
  },

  contentExpanded: {
    backgroundColor: theme.palette.background.default,
    minHeight: 'unset',
  },
  description: {
    fontSize: '1.0625rem',
    fontWeight: 700,
    margin: '.516rem 0 2.3rem',
    color: '#7a8498', // @TODO: Add to the TopResumeTheme
    textAlign: 'center',
  },

  banner: {
    backgroundColor: '#e7e7',
    color: theme.palette.background.default,
    lineHeight: 'normal',
    textAlign: 'center',
  },
  hideOnSmall: {
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
  hideOnSmall3Col: {
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  popularBanner: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '6px 6px 0 0',
    '&&': {
      // Increase the specificity to make sure that the display: 'none' doesn't affect
      display: 'block',
    },
  },
  taggedPopularBanner: {
    top: '1.5rem',
    lineHeight: '0.5rem',
    boxSizing: 'inherit',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    left: '-0.5rem',
    padding: '0.7rem 1rem 0.5rem',
    margin: '0',
    letterSpacing: '.1em',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#ee8d49',
    zIndex: 3,
    fontFamily: 'proxima-nova,Helvetica,Arial,sans-serif',
    [theme.breakpoints.down('md')]: {
      top: '0rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 0.6rem 0.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      left: '1.2rem',
      top: '0.2rem',
    },
    '&:before': {
      content: '""',
      width: '0',
      bottom: '-0.5rem',
      position: 'absolute',
      display: 'block',
      border: '0.5rem solid #e46b15',
      zIndex: -2,
      left: '-1rem',
      borderRightWidth: '0.5rem',
      borderLeftColor: 'transparent',
    },
    '&:after': {
      content: '""',
      bottom: '-0.5rem',
      position: 'absolute',
      display: 'block',
      borderStyle: 'solid',
      borderColor: '#ec7e32 #e46b15 #e46b15 #e46b15',
      zIndex: -1,
      left: '0',
      borderWidth: '0.5rem 0 0 0.5rem',
    },
  },

  secondaryCta: {
    fontWeight: 600,
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  highlightedContainer: {
    paddingTop: '3rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '4.5rem',
    },
  },
  lowerContainer: {
    textAlign: 'center',
    padding: '1rem 0rem 1rem 0rem',
  },
  splitPayment: {
    display: 'inline-block',
    fontSize: '0.825rem',
    lineHeight: '1.25rem',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& span': {
      fontSize: '2rem',
      fontWeight: 500,
    },
    '&&': {
      marginBlock: '0.5em',
    },
  },
  gridItem: {
    width: '100%', // Default to full width
    [theme.breakpoints.up('md')]: {
      width: '33.33%!important', // Adjust width on medium and larger screens
    },
  },
  responsiveGrid: {
    // For mobile devices, display the carousel
    display: 'block',
    opacity: 0, // Start with the component hidden
    animation: `${fadeIn} 0.1s 0.5s forwards`, // Animation to fade in with 1 second delay
    [theme.breakpoints.up('md')]: {
      display: 'none', // Hide on desktop
    },
  },
  responsiveContainer: {
    display: 'none', // Hide by default
    [theme.breakpoints.up('md')]: {
      display: 'flex', // Show only on desktop
    },
  },
}))
