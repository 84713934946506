import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import TrustPilotRatingWidget from '@talentinc/gatsby-theme-ecom/components/TrustPilot/TrustPilotRatingWidget'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import { useStyles } from './HeroV2.styles'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'

export type Props = {
  pageKind: string
  shouldRenderToggle?: boolean
}

export function HeroV2(props: Props) {
  const { pageKind, shouldRenderToggle } = props
  const { cx, classes } = useStyles({ pageKind })
  const brand = useBrand()
  const telemetry = useBETelemetry()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const jobSearchTabText = isMobile
    ? 'Resume + Job Search'
    : 'Resume + Job Search Services'

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        {pageKind === 'Job Search' ? (
          <Box className={classes.title_wraper}>
            <Typography variant="h1" className={classes.title}>
              Your job search just got easier
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              Our team of experts will write your story, find jobs for you, apply for
              you, and prepare you to get the job you want.
            </Typography>
          </Box>
        ) : (
          <Box className={classes.title_wraper}>
            <Typography variant="h2" className={classes.subtitle1}>
              Professional {brand.flagshipProduct} Writing Services
            </Typography>
            <Typography variant="h1" className={classes.title}>
              Land your next job faster
            </Typography>
            <Typography variant="h6" className={classes.subtitle2}>
              Our services have helped over 1 million professionals land more
              interviews and get hired faster.
            </Typography>
          </Box>
        )}

        <TrustPilotRatingWidget
          reference={{ brand: 'TopResume', variant: 'FiveStars Alternative' } as any}
        />

        {brand.isTopResume && shouldRenderToggle ? (
          <Box className={classes.tabs_wrapper}>
            <Box className={classes.tabs}>
              <button
                className={cx(
                  classes.tab,
                  pageKind === 'Resume Writing' ? 'active' : null
                )}
                onClick={() => {
                  telemetry.track({
                    event: 'click_resume_writing',
                    properties: { section: pageKind },
                  })
                  window.location.href = `/resume-writing${window.location.search}`
                }}
              >
                <span>Resume Writing</span>
              </button>
              <button
                className={cx(
                  classes.tab,
                  pageKind === 'Job Search' ? 'active' : null
                )}
                onClick={() => {
                  telemetry.track({
                    event: 'click_job_search_services',
                    properties: { section: pageKind },
                  })
                  window.location.href = `/job-search${window.location.search}`
                }}
              >
                <span>{jobSearchTabText}</span>
              </button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: 56 }} />
        )}
      </Box>
    </Box>
  )
}
