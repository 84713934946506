import React from 'react'
import { Grid, Box, Container } from '@mui/material'
import { INLINES } from '@contentful/rich-text-types'
import { ProductPackagesSection as ProductPackagesSectionType } from '../../types/productPageV2'
import formatContentfulClasses from '../../utils/customClasses'
import RichText from '../RichText'
import { BindToFields } from '../../types/contentfulAttributes'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import useHide from '../../hooks/useHide'
import { usePricePageStyles } from './styles'
import NewProductPackagesContainer from './NewProductPackageContainerV4'
import ProductGroupPageSEO from '../SEO/ProductGroupSEO'

const HEROSECTION_IMAGE_CDN =
  'https://images.ctfassets.net/7thvzrs93dvf/1B0U1uZASvq7pFNOHaMyHL/64ea507048541e4cc815fb7af1dd0157/herosectiongirl.png'

export interface ProductPackagesSectionProps {
  packagesSection: ProductPackagesSectionType
  sectionClass?: string
}

const NewProductPackagesSectionV4: React.FC<ProductPackagesSectionProps> = ({
  packagesSection,
  sectionClass,
}) => {
  const { classes, cx } = usePricePageStyles()
  const [open, setOpen] = React.useState(false)
  const handleClick = () => setOpen(!open)
  const applyContentfulStyles = createContentfulClassGetter(packagesSection.css)
  const hideClasses = useHide(packagesSection.hideOn)

  // We show a different layout when we view the hero section that contains packages
  if (packagesSection.codeClasses?.includes('three-package-hero')) {
    return (
      <>
        <ProductGroupPageSEO
          productPackageRows={packagesSection.productPackageRows}
        />
        <Box
          className={cx(
            classes.box,
            sectionClass,
            classes.heroContainer,
            ...formatContentfulClasses(packagesSection.codeClasses),
            hideClasses
          )}
        >
          <Grid className={classes.bgHeroNew} container spacing={3}>
            <Grid item className={classes.bgMobileLeft} xs={12} md={9}>
              {packagesSection.sectionText && (
                <Box
                  className={cx(
                    classes.textBoxSection,
                    applyContentfulStyles(
                      BindToFields.SectionTextProductPackagesSection
                    )
                  )}
                >
                  <RichText
                    cssEntries={packagesSection.css}
                    rawBody={packagesSection.sectionText.raw}
                    references={packagesSection.sectionText.references}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Box className={classes.hideImage}>
                <img src={HEROSECTION_IMAGE_CDN} alt="Hero Section Girl" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className={cx(
            classes.box,
            sectionClass,
            classes.cardsContainer,
            ...formatContentfulClasses(packagesSection.codeClasses),
            hideClasses
          )}
        >
          {packagesSection.productPackageRows && (
            <Box
              className={cx(
                classes.cardsBox,
                classes.bgMobileRight,
                classes.liftUp
              )}
            >
              <NewProductPackagesContainer
                productPackageRows={packagesSection.productPackageRows}
                css={packagesSection.css}
              />
              {packagesSection.cta && (
                <Box
                  className={cx(
                    classes.ctaContainer,
                    applyContentfulStyles(BindToFields.WrapperCTA)
                  )}
                >
                  <RichText
                    cssEntries={packagesSection.css}
                    rawBody={packagesSection.cta.raw}
                    references={packagesSection.cta.references}
                    options={{
                      props: {
                        [INLINES.ENTRY_HYPERLINK]: {
                          className: classes.cta,
                          open,
                          handleClick,
                        },
                        [BLOCKS.HEADING_1]: {
                          className: applyContentfulStyles(
                            BindToFields.RichTextH1
                          ),
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      <ProductGroupPageSEO
        productPackageRows={packagesSection.productPackageRows}
      />
      <Box
        className={cx(
          classes.box,
          sectionClass,
          ...formatContentfulClasses(packagesSection.codeClasses),
          hideClasses
        )}
      >
        <Container>
          <Grid className={classes.bgHeroNew} container spacing={3}>
            {packagesSection.sectionText && (
              <Grid item className={classes.bgMobileLeft} xs={12} md={9}>
                <Box
                  className={cx(
                    classes.textBoxSection,
                    applyContentfulStyles(
                      BindToFields.SectionTextProductPackagesSection
                    )
                  )}
                >
                  <RichText
                    cssEntries={packagesSection.css}
                    rawBody={packagesSection.sectionText.raw}
                    references={packagesSection.sectionText.references}
                  />
                </Box>
              </Grid>
            )}
            <Grid item className={classes.hideImage} xs={12} md={3}>
              <Box>
                <img src={HEROSECTION_IMAGE_CDN} alt="Hero Section Girl" />
              </Box>
            </Grid>
          </Grid>
        </Container>
        {packagesSection.productPackageRows && (
          <Box className={(classes.cardsBox, classes.bgMobileRight)}>
            <NewProductPackagesContainer
              productPackageRows={packagesSection.productPackageRows}
              css={packagesSection.css}
            />
            {packagesSection.cta && (
              <Box
                className={cx(
                  classes.ctaContainer,
                  applyContentfulStyles(BindToFields.WrapperCTA)
                )}
              >
                <RichText
                  cssEntries={packagesSection.css}
                  rawBody={packagesSection.cta.raw}
                  references={packagesSection.cta.references}
                  options={{
                    props: {
                      [INLINES.ENTRY_HYPERLINK]: {
                        className: classes.cta,
                        open,
                        handleClick,
                      },
                      [BLOCKS.HEADING_1]: {
                        className: applyContentfulStyles(
                          BindToFields.RichTextH1
                        ),
                      },
                      [BLOCKS.PARAGRAPH]: {
                        className: applyContentfulStyles(
                          BindToFields.ParagraphCTA
                        ),
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default NewProductPackagesSectionV4
