import React, { useEffect } from 'react'
import { Box, Container, Grid } from '@mui/material'
import { PackageVariant, ProductPackageDetail } from '../../types/productPageV2'
import { BrandNames } from '../../types/brand'
import { BindToFields, CssEntry } from '../../types/contentfulAttributes'
import { createContentfulClassGetter } from '../../utils/contentful'
import { useSKU } from '../../hooks/useSKUs'
import useBrand from '../../hooks/useBrand'
import useDiscountToken from '../../hooks/useDiscountToken'
import { usePricePageStyles } from './styles'
import PurchaseCard from './NewPurchaseCardV6'
import PurchaseCardZipJob from '../ProductPackages/PurchaseCardZipJob'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry' // Import the telemetry hook

export interface ProductPackageDetailProps {
  productPackageDetail: ProductPackageDetail
}

interface Props {
  productPackageRows: ProductPackageDetail[]
  css: CssEntry[] | null
}

const NewProductPackagesContainerV6: React.FC<Props> = ({
  productPackageRows,
  css,
}) => {
  const { classes, cx } = usePricePageStyles()
  const brand = useBrand()
  const applyContentfulStyles = createContentfulClassGetter(css)
  const telemetry = useBETelemetry() // Initialize telemetry hook

  useEffect(() => {
    // Track the landing page visit event using telemetry
    telemetry.track({
      event: 'visit_plans_page',
    })
  }, []) // Use an empty dependency array to run only on mount

  return (
    <Box
      className={cx({
        [classes.cards]: true,
        [classes.cardsZipjob]: brand.name?.toLowerCase() === BrandNames.zipJob,
        [classes.cards4]: productPackageRows.length === 2,
      })}
    >
      <Container
        className={applyContentfulStyles(BindToFields.OutermostWrapper)}
        disableGutters
      >
        <Grid container spacing={2}>
          {productPackageRows.slice(0, 4).map((productPackageDetail) => {
            const isPremierOrSignaturePackage =
              productPackageDetail.codeClasses?.includes('premier') ||
              productPackageDetail.codeClasses?.includes('signature')
            return (
              <Grid
                item
                className={cx(classes.gridItem, {
                  [classes.fullWidth]: isPremierOrSignaturePackage,
                })}
                key={productPackageDetail.contentful_id}
                md={isPremierOrSignaturePackage ? 12 : 4} // Use full width for elite or apex package
              >
                {productPackageDetail.variant === PackageVariant.free ||
                !productPackageDetail.productPackage ? (
                  <Grid>
                    <PurchaseCardWithoutSku
                      productPackageDetail={productPackageDetail}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <PurchaseCardWithSku
                      productPackageDetail={productPackageDetail}
                    />
                  </Grid>
                )}
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export const PurchaseCardWithoutSku: React.FC<ProductPackageDetailProps> = ({
  productPackageDetail,
}) => {
  const brand = useBrand()
  return (
    <>
      {brand.name === 'ZipJob' ? (
        <PurchaseCardZipJob {...productPackageDetail} />
      ) : (
        <PurchaseCard {...productPackageDetail} />
      )}
    </>
  )
}

export const PurchaseCardWithSku: React.FC<ProductPackageDetailProps> = ({
  productPackageDetail,
}) => {
  const urlDiscountToken = useDiscountToken()
  const sku = useSKU(
    productPackageDetail.productPackage.items,
    productPackageDetail.productPackage.planCode,
    urlDiscountToken ?? productPackageDetail.discountToken,
    productPackageDetail.productPackage.sort
  )
  const brand = useBrand()

  return (
    <>
      {brand.name === 'ZipJob' ? (
        <PurchaseCardZipJob
          {...productPackageDetail}
          planCode={sku?.plan_code}
          amount={sku?.amount}
          discountedAmount={sku?.discountedAmount}
          title={productPackageDetail.title || sku?.plan_short_name}
          formattedAmount={sku?.formattedOriginalAmount}
          formattedDiscount={sku?.formattedDiscount}
          purchasePath={sku?.purchasePath}
          sort={sku?.sort}
        />
      ) : (
        <PurchaseCard
          {...productPackageDetail}
          planCode={sku?.plan_code}
          amount={sku?.amount}
          discountedAmount={sku?.discountedAmount}
          title={productPackageDetail.title || sku?.plan_short_name}
          formattedAmount={sku?.formattedOriginalAmount}
          formattedDiscount={sku?.formattedDiscount}
          purchasePath={sku?.purchasePath}
          alternatePlan={sku?.alternate_plan}
        />
      )}
    </>
  )
}

export default NewProductPackagesContainerV6
