import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import Link from '@talentinc/gatsby-theme-ecom/components/Link'
import { AuthorSocialMedia } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import SvgLinkedIn from '@talentinc/gatsby-theme-ecom/images/social-linkedin.inline.svg'
import SvgInstagram from '@talentinc/gatsby-theme-ecom/images/social-instagram.inline.svg'
import SvgFacebook from '@talentinc/gatsby-theme-ecom/images/social-facebook.inline.svg'
import SvgTwitter from '@talentinc/gatsby-theme-ecom/images/social-twitter.inline.svg'

interface Props {
  data: AuthorSocialMedia
}

const AuthorSocialMediaSection: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { authorName, linkedIn, instagram, facebook, twitter } = data

  return (
    <Box className={classes.container}>
      <Typography variant="body1" className={classes.text}>
        {t('authorSocialMedia.followOn', { author: authorName })}
      </Typography>
      {linkedIn && (
        <Link
          href={linkedIn}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="LinkedIn"
          className={classes.socialIconWrapper}
        >
          <SvgLinkedIn />
        </Link>
      )}
      {instagram && (
        <Link
          href={instagram}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Instagram"
          className={classes.socialIconWrapper}
        >
          <SvgInstagram />
        </Link>
      )}
      {facebook && (
        <Link
          href={facebook}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Facebook"
          className={classes.socialIconWrapper}
        >
          <SvgFacebook />
        </Link>
      )}
      {twitter && (
        <Link
          href={twitter}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Twitter"
          className={classes.socialIconWrapper}
        >
          <SvgTwitter />
        </Link>
      )}
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
  },
  text: {
    color: theme.colors.gray.a400,
  },
  socialIconWrapper: {
    marginLeft: '0.625rem',
    display: 'flex',
  },
}))

export default AuthorSocialMediaSection
