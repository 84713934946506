import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  ReviewSectionNonTrustPilot as ReviewSectionType,
  ReviewSectionVariants,
} from '../../types/review'
import ReviewsCarousel from './ReviewsCarousel'
import ReviewItem from './ReviewItem'
import ReviewList from './ReviewList'
import RichText from '../RichText'
import getColor, { getTextColor } from '../../utils/colors'
import useHide from '../../hooks/useHide'

export interface ReviewSectionNonTrustPilotProps {
  section: ReviewSectionType
}

const ReviewSectionNonTrustPilot: React.FC<ReviewSectionNonTrustPilotProps> = ({
  section,
}) => {
  const { classes, cx } = useStyles(section)
  const hideClasses = useHide(section.hideOn)

  return (
    <Box className={cx(classes.box, classes.background, hideClasses)}>
      {section.body && (
        <Box className={classes.boxText}>
          <RichText rawBody={section.body.raw} />
        </Box>
      )}
      {section.variant === ReviewSectionVariants.Carousel && (
        <ReviewsCarousel>
          {section.reviews &&
            section.reviews.map((review) => (
              <ReviewItem review={review} key={review.contentful_id} />
            ))}
        </ReviewsCarousel>
      )}
      {section.variant === ReviewSectionVariants.List && section.reviews && (
        <ReviewList reviews={section.reviews} />
      )}
    </Box>
  )
}

const useStyles = makeStyles<{ backgroundColor: string }>()(
  (theme, { backgroundColor }) => {
    const bgColor = getColor(backgroundColor, theme)
    const textColor = getTextColor(backgroundColor, theme)

    return {
      background: {
        backgroundColor: bgColor,
        color: textColor,
      },
      box: {
        padding: '4.19rem 6% 4rem',
      },
      boxText: {
        marginBottom: '1.5rem',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
        },
      },
    }
  }
)

export default ReviewSectionNonTrustPilot
