import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'

const slideUp = keyframes`
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
`

export const useStyles = makeStyles<
  { pageKind: string },
  'content' | 'img' | 'title_wraper'
>()((theme, { pageKind }, classes) => ({
  root: {
    display: 'flex',
    margin: '55x 0 32px -24px',
    paddingTop: '5px',
    paddingBottom: '5px',

    [theme.breakpoints.down('lg')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },

    [theme.breakpoints.down('md')]: {
      margin: '32px 0 0 0',
      paddingLeft: 0,
      paddingRight: 0,

      [`& .${classes.content}`]: {
        padding: '24px 0 0 0',
        alignItems: 'center',
        textAlign: 'center',
        margin: 'auto',

        '& h2': {
          fontSize: '17px',
        },
        '& h1': {
          fontSize: '2.64em',
        },
      },

      [`& .${classes.title_wraper}`]: {
        alignItems: 'center',
      },
    },

    [theme.breakpoints.up('lg')]: {
      [`& .${classes.title_wraper}`]: {
        maxWidth: '80%',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    padding: '24px 0 0 24px',
    gap: '18px',
    zIndex: 1,
    position: 'relative',
  },
  title_wraper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  title: {
    margin: '0px !important',
  },
  subtitle1: {
    margin: '0px !important',
    color: '#5A6377',
    fontSize: '20px',
    textTransform: 'capitalize',
  },
  subtitle2: {
    margin: '0px !important',
    fontSize: '20px',
    fontFamily: 'proxima-nova,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    lineHeight: '28px',
    color: '#202124',
    textTransform: 'none',
    maxWidth: '576px',
  },
  img: {
    padding: '32px 0 0 24px',

    '& > img': {
      width: '362px',
      height: '295px',
    },
  },
  tabs_wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    animation: `150ms ${slideUp}`,

    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  },
  tabs: {
    width: 'fit-content',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(24px)',
    backgroundColor: 'rgba(120, 120, 120, 0.1)',
    backgroundBlendMode: 'multiply',
    borderRadius: '12px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0',

      '& button': {
        fontSize: '14px',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  tab: {
    background: 'none',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    margin: '0 4px',
    fontSize: '19px',
    fontWeight: 400,
    userSelect: 'none',
    width: '300px',
    whiteSpace: 'nowrap',
    opacity: 0.5,
    transition: 'opacity ease 300ms',

    '&:focus': { outline: 0 },

    '&.active': {
      pointerEvents: 'none',
      backgroundColor: 'white',
      height: '48px',
      boxShadow: '0px 2px 4px 0px #0F387114, 0px 0px 1px 0px #0F387152',
      opacity: 1,
    },

    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))
