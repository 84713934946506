const newestTab = 'Newest Post'
const popularTab = 'Popular Post'

export const AuthorPostConstants = {
  newestTab,
  popularTab,
  authorPostTabs: [newestTab, popularTab],
  incrementValue: 6,
  authorPostsLimit: 'authorPostsLimit',
  currentPostsTab: 'currentPostsTab',
}
