import React from 'react'
import { useTranslation } from 'react-i18next'
import useLocation from 'react-use/lib/useLocation'
import useDiscountCode from '../../hooks/useDiscountCode'
import useDiscountToken from '../../hooks/useDiscountToken'
import {
  AlternatePlan,
  SnakeCaseToCamelCase,
  useAllSKUs,
  useSKU,
} from '../../hooks/useSKUs'
import { getCurrencySymbol } from '../../utils/currency'
import { urlPathJoin } from '../../utils/url'
import Link from '../Link'
import { usePricePageStyles } from './styles'

type SplitPaymentProps = {
  [k in keyof AlternatePlan as SnakeCaseToCamelCase<k>]: NonNullable<
    AlternatePlan[k]
  >
}

export function SplitPaymentLink({
  intervalTimes,
  planCode,
  amount,
}: SplitPaymentProps): React.JSX.Element {
  const { classes } = usePricePageStyles()
  const { t } = useTranslation('translation')
  const sku = useSKU([], planCode)
  const [skuFallback] = useAllSKUs()
  const location = useLocation()
  const discountToken = useDiscountToken()
  const { data } = useDiscountCode(planCode, discountToken)

  if (data) {
    const url = urlPathJoin([`/purchase/${data.plan_code}`], {
      baseURL: location.origin,
      search: { dt: data.discount_token },
    })

    return (
      <Link
        className={classes.splitPayment}
        href={url}
        dangerouslySetInnerHTML={{
          __html: t('splitPaymentText', {
            intervalTimes,
            amount: data.sticker_price - data.discount_value,
            currency: data.currency_symbol,
          }),
        }}
      />
    )
  }

  return (
    <Link
      className={classes.splitPayment}
      href={`/purchase/${planCode}`}
      dangerouslySetInnerHTML={{
        __html: t('splitPaymentText', {
          intervalTimes,
          amount,
          currency: getCurrencySymbol(
            sku?.currency_code ?? skuFallback?.currency_code
          ),
        }),
      }}
    />
  )
}
