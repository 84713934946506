import { useAllSKUs } from './useSKUs'
import { ProductPackageDetail, SellingPoint } from '../types/productPageV2'
import isEqual from 'lodash/isEqual'

export function useProductDetails(productPackageRows: ProductPackageDetail[]): {
  plan_name: string
  sellingPoints: SellingPoint[]
  plan_code: string | undefined
  currency_code: string | undefined
  amount: number | undefined
}[] {
  const skus = useAllSKUs()
  return productPackageRows.map((row) => {
    const sku = skus.find((sku) =>
      isEqual(
        row.productPackage?.items?.slice()?.sort(),
        sku.queryKeys?.slice()?.sort()
      )
    )

    return {
      plan_name: row.title,
      sellingPoints: row.sellingPoints,
      plan_code: sku?.plan_code,
      currency_code: sku?.currency_code,
      amount: sku?.amount,
    }
  })
}
