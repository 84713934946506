import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { SpacerComponent } from '../../types/landingPageV2'
import getColor from '../../utils/colors'
import useHide from '../../hooks/useHide'

export interface SpacerProps {
  item: SpacerComponent
}

const Spacer: React.FC<SpacerProps> = ({ item }) => {
  const { classes, cx } = useStyles(item)
  const hideClasses = useHide(item.hideOn)
  return <Box className={cx(classes.box, hideClasses)} />
}

export const useStyles = makeStyles<{ color: string; size: string }>()(
  (theme, { color, size }) => {
    const bgColor = getColor(color, theme)

    const heights = {
      small: '1rem',
      medium: '2rem',
      large: '3.25rem',
    }

    const mdHeights = {
      small: '5rem',
      medium: '1rem',
      large: '1.625rem',
    }

    return {
      // @ts-ignore
      box: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: bgColor,
        // @ts-ignore
        height: heights[size] ?? '0',
        [theme.breakpoints.down('md')]: {
          // @ts-ignore
          height: mdHeights[size] ?? '0',
        },
      },
    }
  }
)

export default Spacer
