import React, { useEffect, useMemo, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSessionQuery } from '@talentinc/gatsby-theme-ecom/components/Providers/UserSession'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'
import { useStyles } from './DynamicProductsSection.styles'
import { DynamicProductsSection as ContentfulDynamicProductsSection } from '../../types/landingPageV2'
import { PackageCard } from './PackageCard'
import { useLeadEvent } from '../../hooks/useCheckoutEvent'
import { HeroV2 } from './HeroV2'
import { usePricePoints } from './data'

type Props = {
  pageKind: ContentfulDynamicProductsSection['pageKind']
}

export function DynamicProductsSection(props: Props) {
  const { pageKind } = props
  const { classes } = useStyles({ pageKind })

  const telemetry = useBETelemetry()

  const [debug, setDebug] = useState(false)
  const [segment, setSegment] = useState('')

  const sessionQuery = useSessionQuery()

  const { pricePoints, match } = usePricePoints(
    pageKind,
    segment || sessionQuery.data?.segmentKey
  )

  const isLoading = sessionQuery.isLoading && !segment

  const isPartnerMonster = useMemo(
    () => sessionQuery.data?.partnerName?.includes('Monster'),
    [sessionQuery.data]
  )

  useLeadEvent({ eventName: 'Checkout Page Viewed', pricePoints, isLoading })

  useEffect(() => {
    const segmentKey = sessionQuery?.data?.segmentKey
    if (segmentKey) {
      localStorage.setItem('segment', segmentKey)

      window.VWO = window.VWO || []
      window.VWO.push(['visitor', { segmentKey }])
    }
  }, [sessionQuery.data])

  useEffect(() => {
    const tmp = localStorage.getItem('segment')

    if (tmp) {
      setSegment(tmp)
    }
  }, [])

  useEffect(() => {
    if (sessionQuery.isFetched) {
      telemetry.track({
        event: 'visit_plans_page',
        properties: {
          section: pageKind,
          segment: match,
        },
      })
    }
    // eslint-disable-next-line
  }, [sessionQuery.data, sessionQuery.isFetched])

  /**
   * DEBUG
   -------------------------------------------------------------------*/
  useEffect(() => {
    const sp = new URLSearchParams(window.location.search)
    setDebug(sp.get('debug') as any)
  }, [])

  return (
    <Box className={classes.root}>
      <Container>
        <HeroV2
          pageKind={pageKind}
          shouldRenderToggle={
            sessionQuery.data && !sessionQuery.isLoading && !isPartnerMonster
          }
        />

        {/* DEBUG
         * -------------------------------------------------------------------*/}
        {debug ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: '3px',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <Typography variant="h5">DEBUG</Typography>
            <select
              value={segment}
              onChange={(e) => {
                const selectedSegment = e.target.value
                setSegment(selectedSegment)

                window.VWO.visitor({ segmentKey: selectedSegment })
              }}
            >
              <option label=""></option>
              {[
                'blue_collar',
                'academic',
                'white_collar_0_5',
                'white_collar_6_10',
                'white_collar_11',
                'unknown',
              ].map((i) => (
                <option key={i} label={i} value={i} />
              ))}
            </select>
            <small>
              API match: <b>{sessionQuery.data?.segmentKey}</b>
            </small>
            <small>
              React match: <b>{match}</b>
            </small>
          </Box>
        ) : null}

        <Box className={classes.grid} id="view_packages">
          {pricePoints.map((pp, i) => (
            <PackageCard
              key={pp.plan_code}
              pricePoint={pp}
              isRecommended={i === 1}
              isHorizontal={pricePoints.length - 1 === i}
              isLoading={isLoading}
              pageKind={pageKind}
              segment={segment || sessionQuery.data?.segmentKey || ''}
            />
          ))}
        </Box>

        <Box className={classes.footer}>
          <Typography variant="h3">60-Day Interview Guarantee</Typography>
          <Typography>
            We're committed to helping our customers succeed. If you don't get called
            for an interview in 60-days, your rewrite is on us.
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
