import React, { useCallback, useReducer } from 'react'
import { Box, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  CountActionType,
  ReviewItem as ReviewItemType,
} from '../../types/review'
import ReviewItem from './ReviewItem'
import { showMoreIncrements } from '../../types/constants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface Props {
  reviews: ReviewItemType[]
}

type CountAction = {
  type: CountActionType.ShowMore
  payload: typeof showMoreIncrements
}

function countReducer(state = showMoreIncrements, action: CountAction) {
  switch (action.type) {
    case CountActionType.ShowMore:
      return state + action.payload
    default:
      return state
  }
}

const ReviewList: React.FC<Props> = ({ reviews }) => {
  // Made it a reducer incase we use show less or reset
  const [count, dispatch] = useReducer(countReducer, showMoreIncrements)
  const { classes } = useStyles()
  const { t } = useTranslation()
  const handleShowMoreClick = useCallback(() => {
    return dispatch({
      type: CountActionType.ShowMore,
      payload: showMoreIncrements,
    })
  }, [])
  return (
    <>
      <Box className={classes.box}>
        {reviews.slice(0, count).map((review) => (
          <ReviewItem review={review} key={review.contentful_id} />
        ))}
      </Box>
      <Button
        disabled={count >= reviews.length}
        className={classes.loadMore}
        variant="outlined"
        color="secondary"
        onClick={handleShowMoreClick}
      >
        {t('reviewList.showMore')}
      </Button>
    </>
  )
}

const useStyles = makeStyles()(() => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  loadMore: {
    display: 'block',
    margin: 'auto',
  },
}))

export default ReviewList
