import React from 'react'
import { Box } from '@mui/material'
import { INLINES } from '@contentful/rich-text-types'
import { ProductPackagesSection as ProductPackagesSectionType } from '../../types/productPageV2'
import formatContentfulClasses from '../../utils/customClasses'
import RichText from '../RichText'
import { BindToFields } from '../../types/contentfulAttributes'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import useHide from '../../hooks/useHide'
import { usePricePageStyles } from './styles'
import NewProductPackagesContainer from './NewProductPackageContainer'
import ProductGroupPageSEO from '../SEO/ProductGroupSEO'

export interface ProductPackagesSectionProps {
  packagesSection: ProductPackagesSectionType
  sectionClass?: string
}

const NewProductPackagesSection: React.FC<ProductPackagesSectionProps> = ({
  packagesSection,
  sectionClass,
}) => {
  const { classes, cx } = usePricePageStyles()
  const [open, setOpen] = React.useState(false)
  const handleClick = () => setOpen(!open)
  const applyContentfulStyles = createContentfulClassGetter(packagesSection.css)
  const hideClasses = useHide(packagesSection.hideOn)

  // We show a different layout when we view the hero section that contains packages
  if (packagesSection.codeClasses?.includes('three-package-hero')) {
    return (
      <>
        <ProductGroupPageSEO
          productPackageRows={packagesSection.productPackageRows}
        />
        <Box
          className={cx(
            classes.box,
            sectionClass,
            classes.heroContainer,
            ...formatContentfulClasses(packagesSection.codeClasses),
            hideClasses
          )}
        >
          {packagesSection.sectionText && (
            <Box
              className={cx(
                classes.textBoxSection,
                applyContentfulStyles(
                  BindToFields.SectionTextProductPackagesSection
                )
              )}
            >
              <RichText
                cssEntries={packagesSection.css}
                rawBody={packagesSection.sectionText.raw}
                references={packagesSection.sectionText.references}
              />
            </Box>
          )}
        </Box>
        <Box
          className={cx(
            classes.box,
            sectionClass,
            classes.cardsContainer,
            ...formatContentfulClasses(packagesSection.codeClasses),
            hideClasses
          )}
        >
          {packagesSection.productPackageRows && (
            <Box className={cx(classes.cardsBox, classes.liftUp)}>
              <NewProductPackagesContainer
                productPackageRows={packagesSection.productPackageRows}
                css={packagesSection.css}
              />
              {packagesSection.cta && (
                <Box
                  className={cx(
                    classes.ctaContainer,
                    applyContentfulStyles(BindToFields.WrapperCTA)
                  )}
                >
                  <RichText
                    cssEntries={packagesSection.css}
                    rawBody={packagesSection.cta.raw}
                    references={packagesSection.cta.references}
                    options={{
                      props: {
                        [INLINES.ENTRY_HYPERLINK]: {
                          className: classes.cta,
                          open,
                          handleClick,
                        },
                        [BLOCKS.HEADING_1]: {
                          className: applyContentfulStyles(
                            BindToFields.RichTextH1
                          ),
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      <ProductGroupPageSEO
        productPackageRows={packagesSection.productPackageRows}
      />
      <Box
        className={cx(
          classes.box,
          sectionClass,
          ...formatContentfulClasses(packagesSection.codeClasses),
          hideClasses
        )}
      >
        {packagesSection.sectionText && (
          <Box
            className={cx(
              classes.textBoxSection,
              applyContentfulStyles(
                BindToFields.SectionTextProductPackagesSection
              )
            )}
          >
            <RichText
              cssEntries={packagesSection.css}
              rawBody={packagesSection.sectionText.raw}
              references={packagesSection.sectionText.references}
            />
          </Box>
        )}
        {packagesSection.productPackageRows && (
          <Box className={classes.cardsBox}>
            <NewProductPackagesContainer
              productPackageRows={packagesSection.productPackageRows}
              css={packagesSection.css}
            />
            {packagesSection.cta && (
              <Box
                className={cx(
                  classes.ctaContainer,
                  applyContentfulStyles(BindToFields.WrapperCTA)
                )}
              >
                <RichText
                  cssEntries={packagesSection.css}
                  rawBody={packagesSection.cta.raw}
                  references={packagesSection.cta.references}
                  options={{
                    props: {
                      [INLINES.ENTRY_HYPERLINK]: {
                        className: classes.cta,
                        open,
                        handleClick,
                      },
                      [BLOCKS.HEADING_1]: {
                        className: applyContentfulStyles(
                          BindToFields.RichTextH1
                        ),
                      },
                      [BLOCKS.PARAGRAPH]: {
                        className: applyContentfulStyles(
                          BindToFields.ParagraphCTA
                        ),
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default NewProductPackagesSection
