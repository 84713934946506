import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemIcon,
  List,
  useTheme,
  Container,
  CardActions,
  Collapse,
  useMediaQuery,
} from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { BLOCKS } from '@contentful/rich-text-types'
import { useTranslation } from 'react-i18next'
import { BGVariants } from '../../types/heroTextSection'
import { PackageVariant, SellingPoint } from '../../types/productPageV2'
import { Button, PurchaseButton } from '../../types/page'
import { ExternalLink } from '../../types/page'
import { usePricePageStyles } from './styles'
import useBrand from '../../hooks/useBrand'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import formatContentfulClasses from '../../utils/customClasses'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BindToFields, CssEntry } from '../../types/contentfulAttributes'
import { MIN_AFFIRM_AMOUNT } from '../../types/constants'
import { AlternatePlan } from '../../hooks/useSKUs'
import { hasNonNullProperties } from '../../utils/utils'
import { SplitPaymentLink } from './NewSplitPaymentLink'
import CheckMarkSVG from '../../images/checkmarkblue.inline.svg'
import CrosDisabled from '../../images/crosmark-disabled.inline.svg'
import NavigationGroupLink from '../NavigationGroup/NavigationGroupLink'
import ProductPriceInsert from './NewProductPriceInsert'
import RichText from '../RichText'
import LinkButton from '../Link/LinkButton'
import AffirmNewTest from '../AffirmNewTest'

export interface PurchaseCardProps {
  title?: string | null
  subtitle?: string | null
  description: string
  sellingPoints: SellingPoint[]
  cta: PurchaseButton | Button | null
  secondaryCta: ExternalLink
  backgroundColor: string
  discountToken: string
  variant: string
  highlightPackage: boolean
  highlightTag: string
  originalAmount?: number
  amount?: number
  discountedAmount?: number
  formattedAmount?: string
  formattedDiscount?: string | null
  planCode?: string
  purchasePath?: string
  priceOverride?: string
  codeClasses: string[] | null
  css: CssEntry[] | null
  alternatePlan?: AlternatePlan
  expanded: boolean
  onExpandClick: () => void
}

const PurchaseCard: React.FC<PurchaseCardProps> = ({
  title,
  subtitle,
  description,
  sellingPoints,
  amount = 0,
  formattedAmount,
  formattedDiscount,
  planCode,
  cta,
  backgroundColor,
  variant,
  highlightPackage,
  highlightTag,
  purchasePath,
  priceOverride,
  codeClasses,
  css,
  alternatePlan,
  expanded,
  onExpandClick,
}) => {
  const { classes, cx } = usePricePageStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'))

  const { t } = useTranslation()
  const brand = useBrand()
  const handlebarsContext = useHandlebarsContext()

  const isPricingPagePackage = codeClasses?.includes('pricing-package')

  const applyContentfulStyles = createContentfulClassGetter(css)

  const getLinkButton = (): JSX.Element | null => {
    if (cta) {
      if (planCode) {
        if (cta.__typename === 'ContentfulButton') {
          return (
            <LinkButton
              mixpanelTracking={cta.mixpanelTracking}
              className={cx(...formatContentfulClasses(cta.codeClasses))}
              href={purchasePath}
              color={cta.color === 'default' ? 'primary' : cta.color}
              variant={cta.variant}
              fullWidth
            >
              {cta.buttonText ?? undefined}
            </LinkButton>
          )
        }

        if (cta.action) {
          if ('slug' in cta.action) {
            return (
              <LinkButton
                mixpanelTracking={cta.mixpanelTracking}
                className={cx(...formatContentfulClasses(cta.codeClasses))}
                to={cta.action.slug ?? purchasePath}
                color={cta.color === 'default' ? 'primary' : cta.color}
                variant={cta.variant}
                fullWidth
              >
                {cta.buttonText ?? undefined}
              </LinkButton>
            )
          }

          if ('url' in cta.action) {
            return (
              <LinkButton
                mixpanelTracking={cta.mixpanelTracking}
                className={cx(...formatContentfulClasses(cta.codeClasses))}
                href={cta.action.url}
                color={cta.color === 'default' ? 'primary' : cta.color}
                variant={cta.variant}
                fullWidth
              >
                {cta.buttonText ?? undefined}
              </LinkButton>
            )
          }
        }
      }
      return (
        <NavigationGroupLink
          key={cta.contentful_id}
          link={cta as Button}
          className={cx(...formatContentfulClasses(cta.codeClasses))}
        />
      )
    }

    return null
  }

  return (
    <Container className={classes.noRightPadding}>
      <Card
        className={cx(
          {
            [classes.priceCard]: true,
            [classes.cardDarkPrimary]:
              backgroundColor === BGVariants.DarkPrimary,
            [classes.cardPopular]: highlightPackage && isPricingPagePackage,
            [classes.threeColPackage]:
              codeClasses?.includes('three-col-package'),
          },
          formatContentfulClasses(codeClasses),
          applyContentfulStyles(BindToFields.Main)
        )}
        elevation={0}
      >
        {highlightPackage ? (
          <Typography
            className={cx(
              classes.banner,
              isPricingPagePackage
                ? classes.taggedPopularBanner
                : classes.popularBanner,
              applyContentfulStyles(BindToFields.HighlightTag)
            )}
            component="p"
            variant="overline"
          >
            {highlightTag ? highlightTag : t('purchaseCard.popular')}
          </Typography>
        ) : (
          <Typography
            className={cx(
              {
                [classes.banner]: true,
              },
              codeClasses?.includes('three-col-package')
                ? classes.hideOnSmall3Col
                : classes.hideOnSmall,
              applyContentfulStyles(BindToFields.HighlightTag)
            )}
            component="p"
            variant="overline"
          ></Typography>
        )}
        <CardContent
          className={cx({
            [classes.priceCardContent]: true,
            [classes.borderRadius]: !highlightPackage && !isPricingPagePackage,
            [classes.contentExpanded]:
              variant === PackageVariant.expandedPackage,
            [classes.contentMostPopular]:
              highlightPackage && !isPricingPagePackage,
          })}
        >
          <Typography
            variant="body1"
            component="div"
            className={classes.priceCardTitle}
          >
            {title}
          </Typography>

          <Typography variant="h3" className={classes.priceCardSubtitle}>
            {subtitle}
          </Typography>

          <Typography variant="body1" className={classes.priceCardDescription}>
            {insertHandlebarsTemplate(description, handlebarsContext)}
          </Typography>

          <Box className={classes.priceCardPrice} component="div">
            <>
              {formattedAmount ? (
                <ProductPriceInsert
                  formattedAmount={formattedAmount}
                  formattedDiscount={formattedDiscount}
                  url={purchasePath}
                  linkPriceClass={classes.priceCardPrice}
                  originalPriceClass={classes.priceCardPrice}
                />
              ) : (
                <>
                  {priceOverride ? (
                    <Typography className={classes.priceCardPrice}>
                      {priceOverride}
                    </Typography>
                  ) : (
                    <Typography className={classes.priceCardPrice}>
                      {t('purchaseCard.free')}
                    </Typography>
                  )}
                </>
              )}
            </>

            <Box className={classes.priceCardMonthlyPrice}>
              {formattedAmount &&
              planCode &&
              !brand.name?.includes('TopCV') &&
              amount > MIN_AFFIRM_AMOUNT ? (
                <AffirmNewTest planCode={planCode} price={amount} />
              ) : brand.isTopCvFr && hasNonNullProperties(alternatePlan) ? (
                <SplitPaymentLink
                  planCode={alternatePlan.plan_code}
                  intervalTimes={alternatePlan.interval_times}
                  amount={alternatePlan.amount}
                />
              ) : (
                <Typography className={classes.oneTimePayment}>
                  {t('purchaseCard.ineligibleForAffirm')}
                </Typography>
              )}
            </Box>
          </Box>

          <CardActions>{getLinkButton()}</CardActions>
        </CardContent>
        <CardContent className={classes.priceFeaturesList}>
          {/* Mobile Features Expansion */}
          {isMobile && (
            <CardActions
              className={classes.priceMobileFeatures}
              onClick={onExpandClick}
            >
              <Typography
                className={classes.priceMobileFeaturesFont}
                component="div"
                style={{ cursor: 'pointer', flexGrow: 1 }}
              >
                What's included
              </Typography>
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </CardActions>
          )}
          <Collapse
            in={isMobile ? expanded : true}
            timeout="auto"
            unmountOnExit
          >
            <List className={classes.priceListFeatures}>
              {sellingPoints.map((point, index) => {
                if (isMdOrLarger && index >= 6) return null
                const isDisabled = point.variant === 'disabled'
                const featureIcon = isDisabled ? (
                  <CrosDisabled />
                ) : (
                  <CheckMarkSVG />
                )
                const textClass = `${classes.priceCardFeature} ${
                  isDisabled ? classes.grayFeature : ''
                }`

                return (
                  <ListItem
                    key={point.contentful_id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '0px',
                      paddingTop: '0px',
                      paddingBottom: '12px',
                      paddingLeft: isDisabled ? '13px' : '16px',
                    }}
                  >
                    <ListItemIcon
                      style={{ minWidth: '20px', marginRight: '10px' }}
                    >
                      {featureIcon}
                    </ListItemIcon>
                    <Typography variant="body1" className={textClass}>
                      <RichText
                        rawBody={point.sellingPointBody.raw}
                        references={point.sellingPointBody.references}
                        options={{
                          props: {
                            [BLOCKS.PARAGRAPH]: {
                              variant: 'body2',
                              className: textClass,
                            },
                          },
                        }}
                      />
                    </Typography>
                  </ListItem>
                )
              })}
            </List>
          </Collapse>
        </CardContent>
      </Card>
    </Container>
  )
}

export default PurchaseCard
