import React, { useState } from 'react'
import { Box, Button, Collapse, Divider, Skeleton, Typography } from '@mui/material'
import { Check, ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material'
import { useStyles } from './PackageCard.styles'
import { PriceInsert } from './PriceInsert'
import { useBETelemetry } from '../../hooks/useTelemetry'
import { PricePoint } from './data'

type Props = {
  pricePoint: PricePoint
  isRecommended?: boolean
  isHorizontal?: boolean
  isLoading?: boolean
  pageKind: string
  segment: string
}

export function PackageCard(props: Props) {
  const { pricePoint, isHorizontal, isRecommended, isLoading, pageKind, segment } =
    props

  const { cx, classes } = useStyles()
  const [isShowingBenefits, setIsShowingBenefits] = useState(false)
  const [isHoverVisible, setIsHoverVisible] = useState(false)
  const telemetry = useBETelemetry()

  const trackButtonClick = (isHover: boolean) => {
    telemetry.track({
      event: 'click_order_now',
      properties: {
        label: pricePoint.sku.plan_short_name,
        plan_code: pricePoint.plan_code,
        hover: isHover ? 'True' : 'False',
        section: pageKind,
        segment,
      },
    })
  }

  if (isHorizontal) {
    return (
      <Box className={cx(classes.root, 'elite-horizontal')}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            width: '100%',
            pb: { md: '64px' },
          }}
        >
          <Box
            sx={{
              flex: { xs: 1, md: 2 },
              pr: { xs: 0, md: 2 },
              maxWidth: { xs: '100%', md: '594px' },
              mb: { xs: 2, md: 0 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                component="span"
                variant="h2"
                className={classes.title_elite}
              >
                {pricePoint.sku.plan_short_name}
              </Typography>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PriceInsert className={classes.price_insert} sku={pricePoint.sku} />
              </Box>
            </Box>
            <Box className={classes.title_description_wrapper}>
              <Typography
                component="span"
                variant="body1"
                className={classes.description_elite}
              >
                {pricePoint?.copy.short_pitch}
              </Typography>
            </Box>
            <Box
              className={classes.elite_selling_points_wrapper}
              sx={{
                display: 'flex',
                mb: { xs: 2, sm: 0, md: '40px' },
                mt: { xs: 0, md: '24px' },
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  mb: { xs: 2, sm: 0 },
                  display: { xs: 'block', sm: 'none' },
                }}
              >
                <ul className={classes.elite_selling_points}>
                  {pricePoint.copy.selling_points.map((i) => (
                    <li key={i} className={classes.selling_point}>
                      <Check fontSize="small" color="primary" />
                      <span>{i}</span>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  mb: { xs: 2, sm: 0 },
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                <ul className={classes.elite_selling_points}>
                  {pricePoint.copy.selling_points
                    .slice(0, Math.ceil(pricePoint.copy.selling_points.length / 2))
                    .map((i) => (
                      <li key={i} className={classes.selling_point}>
                        <Check fontSize="small" color="primary" />
                        <span>{i}</span>
                      </li>
                    ))}
                </ul>
              </Box>
              <Box sx={{ flex: 1, display: { xs: 'none', sm: 'block' } }}>
                <ul className={classes.selling_points}>
                  {pricePoint.copy.selling_points
                    .slice(Math.ceil(pricePoint.copy.selling_points.length / 2))
                    .map((i) => (
                      <li key={i} className={classes.selling_point}>
                        <Check fontSize="small" color="primary" />
                        <span>{i}</span>
                      </li>
                    ))}
                </ul>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
                width: '100%',
              }}
            >
              <Button
                onClick={() => {
                  trackButtonClick(false)
                  window.location.href = `/purchase/${pricePoint.plan_code}${window.location.search}`
                }}
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  flex: { sm: 1 },
                  mb: { xs: 1, sm: 0 },
                }}
              >
                Order Now
              </Button>
              <Box
                className={classes.learnMoreWrapper}
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  id="learn-more-button"
                  className={classes.learnMoreButton}
                  onClick={() => setIsHoverVisible(true)}
                  sx={{
                    backgroundColor: '#F7F9FC',
                    width: '100%',
                    padding: '13px 27px',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: '19px',
                    lineHeight: '24px',
                    color: '#656E83',
                    cursor: 'pointer',
                  }}
                >
                  <Typography>Learn More</Typography>
                  <InfoOutlined sx={{ fontSize: '16px', marginLeft: '4px' }} />
                </Box>

                {pricePoint.copy.hover.items.length > 0 && !isLoading && isHoverVisible ? (
                  <Box 
                    className={cx('benefits_hover', classes.benefits_hover)}
                    onMouseLeave={() => setIsHoverVisible(false)}
                  >
                    <Box className={classes.benefits_hover_title}>
                      <Typography variant="h3">
                        {segment !== 'unknown' &&
                          `Your ${pricePoint.sku.plan_short_name} Path to Success Includes`}
                      </Typography>

                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          trackButtonClick(true)
                          window.location.href = `/purchase/${pricePoint.plan_code}${window.location.search}`
                        }}
                      >
                        Order Now
                      </Button>
                    </Box>

                    <Divider />

                    <Box className={classes.benefits_grid}>
                      {pricePoint.copy.hover.items.map((i, index) => {
                        if (!i) return <Box key={pricePoint.plan_code + index} />

                        return (
                          <Box
                            key={pricePoint.plan_code + i.title}
                            className={classes.benefit_item}
                          >
                            <Typography>
                              <img
                                src="https://images.ctfassets.net/7thvzrs93dvf/5jIr10503JPkWMZh6FG6JW/ea7943312b09500fc49445d213e7efb6/Color_Icons.png?w=57&h=56&q=90&fm=png"
                                alt=""
                              />
                              <span
                                style={{
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'wrap',
                                  color: 'black',
                                  lineHeight: '1.4',
                                }}
                              >
                                {i.title}
                              </span>
                            </Typography>
                            <Typography>{i.description}</Typography>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box
                sx={{ display: { xs: 'none', sm: 'block' }, minWidth: '194.17px' }}
              >
                <PriceInsert className={classes.price_insert} sku={pricePoint.sku} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: { xs: 1, md: 'auto' },
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <img
              src="https://images.ctfassets.net/7thvzrs93dvf/3E5FoNvXwzhMQ1s7E17ZPb/2d6f653fb44c02e6243a6b834ee091aa/Group_881234608.png?w=238&h=318&q=90"
              alt=""
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#fff',
            width: '100%',
            height: '100%',
            zIndex: 999,
            borderRadius: '20px',
            overflow: 'hidden',
          }}
        >
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        </Box>
      ) : null}

      {isRecommended ? (
        <Box className={classes.recommended_wrapper}>
          <Typography className={classes.recommended} variant="overline">
            Most Popular
          </Typography>
        </Box>
      ) : null}

      <Box className={classes.title_description_wrapper}>
        <Box className={classes.title_wrapper}>
          <Typography component="span" variant="h2" className={classes.title}>
            {pricePoint.sku.plan_short_name}
          </Typography>
          <PriceInsert className={classes.price_insert} sku={pricePoint.sku} />
        </Box>
        <Typography component="span" className={classes.description}>
          {pricePoint.copy.very_short_pitch}
        </Typography>
      </Box>

      <Box className={classes.cta_wrapper}>
        <PriceInsert className={classes.price_insert} sku={pricePoint.sku} />
        <Button
          onClick={() => {
            trackButtonClick(false)
            window.location.href = `/purchase/${pricePoint.plan_code}${window.location.search}`
          }}
        >
          Order Now
        </Button>
      </Box>

      <ul className={classes.selling_points}>
        {pricePoint.copy.selling_points.map((i) => (
          <li key={i} className={classes.selling_point}>
            <Check fontSize="small" color="primary" />
            <span>{i}</span>
          </li>
        ))}
      </ul>

      <Collapse in={isShowingBenefits} timeout="auto" unmountOnExit>
        {pricePoint.copy.hover.items.map((i) => {
          if (!i) return null

          return (
            <Box
              key={pricePoint.plan_code + i.title}
              className={classes.benefit_item}
            >
              <Typography>
                <img
                  src="https://images.ctfassets.net/7thvzrs93dvf/5jIr10503JPkWMZh6FG6JW/ea7943312b09500fc49445d213e7efb6/Color_Icons.png?w=57&h=56&q=90&fm=png"
                  alt=""
                />
                <span
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    color: 'black',
                    lineHeight: '1.4',
                  }}
                >
                  {i.title}
                </span>
              </Typography>
              <Typography>{i.description}</Typography>
            </Box>
          )
        })}
      </Collapse>

      <Button
        sx={{
          mt: 'auto !important',
          '&:hover': { boxShadow: 'none !important' },
          display: { sm: 'flex', md: 'none' },
        }}
        variant="text"
        onClick={() => setIsShowingBenefits((p) => !p)}
        fullWidth
        disableElevation
      >
        {isShowingBenefits ? (
          <>
            Hide Benefits
            <ExpandLess />
          </>
        ) : (
          <>
            Show Benefits
            <ExpandMore />
          </>
        )}
      </Button>

      <Box
        className={classes.learnMoreWrapper}
        sx={{
          display: { xs: 'none', sm: 'flex' },
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          id="learn-more-button"
          className={classes.learnMoreButton}
          onClick={() => setIsHoverVisible(true)}
          sx={{
            backgroundColor: '#F7F9FC',
            width: '100%',
            padding: '13px 27px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '19px',
            lineHeight: '24px',
            color: '#656E83',
            cursor: 'pointer',
          }}
        >
          <Typography>Learn More</Typography>
          <InfoOutlined sx={{ fontSize: '16px', marginLeft: '4px' }} />
        </Box>

        {pricePoint.copy.hover.items.length > 0 && !isLoading && isHoverVisible ? (
          <Box 
            className={cx('benefits_hover', classes.benefits_hover)}
            onMouseLeave={() => setIsHoverVisible(false)}
          >
            <Box className={classes.benefits_hover_title}>
              <Typography variant="h3">
                {segment !== 'unknown' &&
                  `Your ${pricePoint.sku.plan_short_name} Path to Success Includes`}
              </Typography>

              <Button
                color="primary"
                size="small"
                onClick={() => {
                  trackButtonClick(true)
                  window.location.href = `/purchase/${pricePoint.plan_code}${window.location.search}`
                }}
              >
                Order Now
              </Button>
            </Box>

            <Divider />

            <Box className={classes.benefits_grid}>
              {pricePoint.copy.hover.items.map((i, index) => {
                if (!i) return <Box key={pricePoint.plan_code + index} />

                return (
                  <Box
                    key={pricePoint.plan_code + i.title}
                    className={classes.benefit_item}
                  >
                    <Typography>
                      <img
                        src="https://images.ctfassets.net/7thvzrs93dvf/5jIr10503JPkWMZh6FG6JW/ea7943312b09500fc49445d213e7efb6/Color_Icons.png?w=57&h=56&q=90&fm=png"
                        alt=""
                      />
                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: 'bold',
                          whiteSpace: 'wrap',
                          color: 'black',
                          lineHeight: '1.4',
                        }}
                      >
                        {i.title}
                      </span>
                    </Typography>
                    <Typography>{i.description}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
