import { RichText } from './post'
import { Brand } from './brand'
import { ExternalLink, InternalLink, SEO } from './page'
import { CTA } from './cta'
import { AssetFluid } from './asset'
import { BGVariants, HideOn } from './heroTextSection'

export interface ReviewCategory {
  contentful_id: string
  __typename: 'ContentfulReviewCategory'
  name: string
  title: string
  body: RichText
  tag: string
  cta: CTA
}

export interface ReviewPage {
  contentful_id: string
  __typename: 'ContentfulReviewPage'
  slug: string
  name: string
  title: string
  headerBody: RichText
  reviewCategories: ReviewCategory[]
  seo: SEO | null
  brand: Brand
  createdAt: string
  updatedAt: string
}

export interface ReviewSectionNonTrustPilot {
  contentful_id: string
  __typename: 'ContentfulReviewSectionNonTrustPilot'
  name: string
  reviews: ReviewItem[]
  variant: ReviewSectionVariants
  body: RichText
  backgroundColor: BGVariants
  codeClasses: string[] | null
  hideOn: HideOn
}

export interface ReviewItem {
  contentful_id: string
  __typename: 'ContentfulReviewItem'
  title: string
  reviewerName: string
  reviewerProfession: string
  reviewerLocation: string | null
  reviewerImage: AssetFluid
  review: RichText
  experience: string
  variant: ReviewItemVariants
  packageBought: ExternalLink | InternalLink
}

export enum ReviewItemVariants {
  WithImage = 'WithImage',
  WithoutImage = 'WithoutImage',
}

export enum ReviewSectionVariants {
  Carousel = 'Carousel',
  List = 'List',
}

export enum CountActionType {
  ShowMore = 'SHOW_MORE',
}
