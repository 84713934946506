import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Box,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Avatar,
  Typography,
} from '@mui/material'
import { makeStyles, withStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import throttle from 'lodash/throttle'
import Link from '@talentinc/gatsby-theme-ecom/components/Link'
import NeutralButton from '@talentinc/gatsby-theme-ecom/components/Buttons/NeutralButton'
import usePageData from '@talentinc/gatsby-theme-ecom/hooks/usePageData'
import useUrlPathBuilder from '@talentinc/gatsby-theme-ecom/hooks/useUrlPathBuilder'
import { PostLink } from '@talentinc/gatsby-theme-ecom/types/landingPage'
import { AuthorPosts as AuthorPostsData } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import { AuthorPostConstants } from '@talentinc/gatsby-theme-ecom/types/author'
import CallToAction from '@talentinc/gatsby-theme-ecom/components/CallToAction'

interface AuthorPostsProps {
  data: AuthorPostsData
  sectionClass?: string
}

const {
  newestTab,
  authorPostTabs,
  incrementValue,
  authorPostsLimit,
  currentPostsTab,
} = AuthorPostConstants

const AuthorPosts: React.FC<AuthorPostsProps> = ({ data, sectionClass }) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const [activeTab, setActiveTab] = useState(newestTab)
  const [limit, setLimit] = useState(incrementValue)

  const listEl = useRef<HTMLElement | null>(null)
  const sidebarEl = useRef<HTMLElement | null>(null)
  const [sidebarTop, setSidebarTop] = useState(true)
  const [sidebarBottom, setSidebarBottom] = useState(false)

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (listEl?.current && sidebarEl?.current) {
          const listTop: number = listEl.current.getBoundingClientRect().top
          const listBottom: number =
            listEl.current.getBoundingClientRect().bottom
          const sidebarElHeight: number =
            sidebarEl.current.getBoundingClientRect().height
          setSidebarTop(listTop > 66)
          setSidebarBottom(listBottom - sidebarElHeight < 154)
        }
      }, 300),
    [listEl, sidebarEl]
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    handleScroll()
  }, [handleScroll, limit])

  const { authorPosts: { nodes: posts = [] } = {} } = usePageData()

  const sortedPosts = useMemo(
    () =>
      Array.from(posts).sort(
        (a: PostLink, b: PostLink) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      ),
    [posts]
  )

  const loadMoreItems = useCallback(() => {
    let updatedLimit = incrementValue
    setLimit((currentLimit) => {
      updatedLimit = Math.min(currentLimit + incrementValue, sortedPosts.length)
      return updatedLimit
    })

    // Saving the posts limit to session storage as gatsby re-renders the whole component
    // on hover of every link and states get reset
    sessionStorage.setItem(authorPostsLimit, JSON.stringify(updatedLimit))
  }, [sortedPosts])

  useLayoutEffect(() => {
    // Pull the current active tab off of the session storage if available in the browser
    const tab = sessionStorage.getItem(currentPostsTab)
    const limit = sessionStorage.getItem(authorPostsLimit)
    if (tab) {
      setActiveTab(tab)
    }
    if (limit) {
      setLimit(JSON.parse(limit))
    }
  }, [])

  if (!activeTab) return null

  const handleChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string
  ) => {
    setActiveTab(newValue)
    setLimit(incrementValue)

    // Set the current selected tab in the session storage as we wanna keep the user
    // in the same section once he/she returns to the landing author page after reading
    // a post
    sessionStorage.setItem(currentPostsTab, newValue)
    sessionStorage.setItem(authorPostsLimit, JSON.stringify(incrementValue))
  }

  return (
    <Box>
      <Tabs value={activeTab} onChange={handleChange} className={sectionClass}>
        {authorPostTabs.map((tab) => (
          <Tab
            key={tab}
            label={<Typography variant="h5">{tab}</Typography>}
            value={tab}
          />
        ))}
      </Tabs>
      <Box className={classes.content}>
        <Box
          // @ts-ignore
          ref={listEl}
          className={cx(classes.container, sectionClass)}
        >
          <List className={classes.list}>
            {sortedPosts.slice(0, limit).map((item: PostLink) => (
              <Post key={item.contentful_id} post={item} />
            ))}
          </List>
          {data.sideCta && (
            <Box className={classes.lgWidgetContainer}>
              <Box
                // @ts-ignore
                ref={sidebarEl}
                className={cx({
                  [classes.sidebar]: true,
                  [classes.sidebarTop]: sidebarTop,
                  [classes.sidebarBottom]: sidebarBottom,
                  [classes.sidebarMid]: !sidebarTop && !sidebarBottom,
                })}
              >
                <CallToAction cta={data.sideCta} />
              </Box>
            </Box>
          )}
        </Box>
        {limit < sortedPosts.length && (
          <Box className={classes.buttonContainer}>
            <NeutralButton onClick={() => loadMoreItems()} variant="outlined">
              {t('search.showMore')}
            </NeutralButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Post = ({ post }: { post: PostLink }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { blogPrefix, urlPathBuilder } = useUrlPathBuilder()

  return (
    <Link
      className={classes.itemLinkWrapper}
      to={urlPathBuilder([blogPrefix, post.slug])}
    >
      <ListItem divider disableGutters className={classes.listItem}>
        <Card elevation={0} className={classes.post}>
          <CardMedia
            className={classes.postImg}
            image={post.featuredImage?.url}
            title={post.title}
          />
          <CardContent className={classes.postDetail}>
            <Box className={classes.postSubCategory}>
              <Typography className={classes.category}>
                {post.categories?.[0]?.name}
              </Typography>
              <Typography variant="caption" className={classes.caption}>
                {post.fields?.readingTime?.text}
              </Typography>
            </Box>
            <Typography className={classes.title} variant="h5">
              {post.title}
            </Typography>
            <Box className={classes.author}>
              <Typography className={classes.authorText}>
                {t('authorPosts.by')}
              </Typography>
              <Avatar
                alt={post.authors[0].name}
                className={classes.authorAvatar}
                src={post.authors[0].picture?.url}
              />
              <Typography className={classes.authorText}>
                {post.authors[0].name}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </ListItem>
    </Link>
  )
}

interface TabsProps {
  value: string
  onChange: any
  className: string | undefined
  children: React.ReactNode
}

const Tabs = withStyles(
  (props: TabsProps) => (
    <MuiTabs {...props} TabIndicatorProps={{ children: <div></div> }} />
  ),
  (theme) => ({
    root: {
      padding: '0 2.25rem',
      [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
        padding: '0 2rem',
      },
    },
    indicator: {
      backgroundColor: theme.palette.action.selected,
    },
    flexContainer: {
      gap: '3.5rem',
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        justifyContent: 'space-between',
        gap: 0,
      },
    },
  })
)

interface TabProps {
  label: React.ReactNode
  value: string
}

const Tab = withStyles(
  (props: TabProps) => <MuiTab disableRipple {...props} />,
  (theme) => ({
    root: {
      letterSpacing: '0.1em',
      padding: 0,
      justifyContent: 'flex-start',
      minWidth: 'fit-content',

      '&:focus': {
        outline: 0,
      },
      textTransform: 'capitalize',
    },
    wrapper: {
      width: 'fit-content',
      alignItems: 'flex-start',
    },
    selected: {
      outline: 0,
    },
  })
)

const useStyles = makeStyles()((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingBlock: '2.5rem',
    display: 'grid',
    gridTemplateColumns: '7.5fr 2.5fr',
    gap: '2rem',

    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      gridTemplateColumns: '1fr',
    },
  },
  list: {
    padding: '0 2.5rem',
  },
  itemLinkWrapper: {
    '&:focus': {
      outline: 0,
    },

    '&:first-child > li': {
      marginTop: 0,
    },
  },
  listItem: {
    borderRadius: '6px',
    marginTop: '2.25rem',
    borderBottom: 'none',
    padding: 0,
  },
  post: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    flex: 1,
    minHeight: '13.25rem',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
    '&:hover': {
      boxShadow: `0 4px 15px 0 ${theme.palette.divider}`,
      textDecoration: 'none',
    },
  },
  postImg: {
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      minWidth: '40%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '100%',
      height: '100%',
    },
    '&&': {
      height: 'auto',
      aspectRatio: '5/3',
      maxHeight: 'none',
      maxWidth: 'none',
    },
  },
  postDetail: {
    alignSelf: 'center',
    '&&': {
      padding: '2em 1.5em 2.5em',
    },
  },
  postSubCategory: {
    marginBottom: '.8rem',
    display: 'flex',
  },
  category: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.action.active,
    marginRight: '1rem',
    fontSize: '0.88em',
  },
  caption: {
    color: theme.palette.text.hint,
    fontWeight: 'bold',
    flex: 1,
  },
  title: {
    color: theme.palette.text.primary,
  },
  author: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.75rem',
  },
  authorText: {
    color: theme.palette.text.secondary,
  },
  authorAvatar: {
    height: '2rem',
    width: '2rem',
    margin: '0 0.5rem',
  },
  lgWidgetContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      display: 'none',
    },
    position: 'relative',
  },
  sidebar: {
    position: 'relative',
    padding: '0 2.5rem',
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      display: 'none',
    },
  },
  sidebarTop: {
    position: 'absolute',
  },
  sidebarMid: {
    position: 'fixed',
    top: '106px',
  },
  sidebarBottom: {
    bottom: '0',
    position: 'absolute',
  },
  buttonContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '.5em 6% 3.5em 6%',
  },
}))

export default AuthorPosts
