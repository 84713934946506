import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemIcon,
  List,
  useTheme,
  Container,
  CardActions,
  useMediaQuery,
} from '@mui/material'
import { BLOCKS } from '@contentful/rich-text-types'
import { useTranslation } from 'react-i18next'
import { BGVariants } from '../../types/heroTextSection'
import { PackageVariant, SellingPoint } from '../../types/productPageV2'
import { Button, PurchaseButton } from '../../types/page'
import { ExternalLink } from '../../types/page'
import { usePricePageStyles } from './styles'
import useBrand from '../../hooks/useBrand'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'
import formatContentfulClasses from '../../utils/customClasses'
import insertHandlebarsTemplate from '../../utils/insertHandlebarsTemplate'
import CheckMarkSVG from '../../images/checkmarkblue.inline.svg'
import { createContentfulClassGetter } from '../../utils/contentful'
import { BindToFields, CssEntry } from '../../types/contentfulAttributes'
import { MIN_AFFIRM_AMOUNT } from '../../types/constants'
import { AlternatePlan } from '../../hooks/useSKUs'
import { hasNonNullProperties } from '../../utils/utils'
import { SplitPaymentLink } from './NewSplitPaymentLinkV4'
import CrosDisabled from '../../images/crosmark-disabled.inline.svg'
import NavigationGroupLink from '../NavigationGroup/NavigationGroupLink'
import ProductPriceInsert from './NewProductPriceInsertV4'
import RichText from '../RichText'
import LinkButton from '../Link/LinkButton'
import AffirmNewTest from '../AffirmNewTest'
import { useBETelemetry } from '../../hooks/useTelemetry' // Import the telemetry hook

export interface PurchaseCardProps {
  title?: string | null
  subtitle?: string | null
  description: string
  sellingPoints: SellingPoint[]
  cta: PurchaseButton | Button | null
  secondaryCta: ExternalLink
  backgroundColor: string
  discountToken: string
  variant: string
  highlightPackage: boolean
  highlightTag: string
  originalAmount?: number
  amount?: number
  discountedAmount?: number
  formattedAmount?: string
  formattedDiscount?: string | null
  planCode?: string
  purchasePath?: string
  priceOverride?: string
  codeClasses: string[] | null
  css: CssEntry[] | null
  alternatePlan?: AlternatePlan
}

const PurchaseCard: React.FC<PurchaseCardProps> = ({
  title,
  subtitle,
  description,
  sellingPoints,
  amount = 0,
  formattedAmount,
  formattedDiscount,
  planCode,
  cta,
  backgroundColor,
  variant,
  highlightPackage,
  highlightTag,
  purchasePath,
  priceOverride,
  codeClasses,
  css,
  alternatePlan,
}) => {
  const { classes, cx } = usePricePageStyles()
  const theme = useTheme()
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'))

  const { t } = useTranslation()
  const brand = useBrand()
  const handlebarsContext = useHandlebarsContext()
  const telemetry = useBETelemetry() // Initialize telemetry hook

  const isPricingPagePackage = codeClasses?.includes('pricing-package')

  const applyContentfulStyles = createContentfulClassGetter(css)

  const getLinkButton = (isHover: boolean): JSX.Element | null => {
    if (cta) {
      const trackButtonClick = () => {
        telemetry.track({
          event: 'click_order_now',
          properties: {
            label: title || 'Unknown Plan', // Use title as label or default to 'Unknown Plan'
            plan_code: planCode || 'Unknown Plan Code',
            hover: isHover ? 'True' : 'False', // Convert boolean to string
            url: window.location.href,
          },
        })
      }

      if (planCode) {
        if (cta.__typename === 'ContentfulButton') {
          return (
            <LinkButton
              className={cx(...formatContentfulClasses(cta.codeClasses))}
              href={purchasePath}
              color={cta.color === 'default' ? 'primary' : cta.color}
              variant={cta.variant}
              fullWidth
              mixpanelTracking={cta.mixpanelTracking}
              onClick={trackButtonClick}
            >
              {cta.buttonText ?? undefined}
            </LinkButton>
          )
        }

        if (cta.action) {
          if ('slug' in cta.action) {
            return (
              <LinkButton
                className={cx(...formatContentfulClasses(cta.codeClasses))}
                to={cta.action.slug ?? purchasePath}
                color={cta.color === 'default' ? 'primary' : cta.color}
                variant={cta.variant}
                fullWidth
                mixpanelTracking={cta.mixpanelTracking}
                onClick={trackButtonClick}
              >
                {cta.buttonText ?? undefined}
              </LinkButton>
            )
          }

          if ('url' in cta.action) {
            return (
              <LinkButton
                className={cx(...formatContentfulClasses(cta.codeClasses))}
                href={cta.action.url}
                color={cta.color === 'default' ? 'primary' : cta.color}
                variant={cta.variant}
                fullWidth
                mixpanelTracking={cta.mixpanelTracking}
                onClick={trackButtonClick}
              >
                {cta.buttonText ?? undefined}
              </LinkButton>
            )
          }
        }
      }
      return (
        <NavigationGroupLink
          key={cta.contentful_id}
          link={cta as Button}
          className={cx(...formatContentfulClasses(cta.codeClasses))}
          onClick={trackButtonClick}
        />
      )
    }

    return null
  }
  return (
    <Container className={classes.noRightPadding}>
      <Card
        className={cx(
          {
            [classes.priceCard]: true,
            [classes.cardDarkPrimary]:
              backgroundColor === BGVariants.DarkPrimary,
            [classes.cardPopular]: highlightPackage && isPricingPagePackage,
            [classes.threeColPackage]:
              codeClasses?.includes('three-col-package'),
          },
          formatContentfulClasses(codeClasses),
          applyContentfulStyles(BindToFields.Main)
        )}
        elevation={0}
      >
        {highlightPackage ? (
          <Typography
            className={cx(
              classes.banner,
              isPricingPagePackage
                ? classes.taggedPopularBanner
                : classes.popularBanner,
              applyContentfulStyles(BindToFields.HighlightTag)
            )}
            component="p"
            variant="overline"
          >
            {highlightTag ? highlightTag : t('purchaseCard.popular')}
          </Typography>
        ) : (
          <Typography
            className={cx(
              {
                [classes.banner]: true,
              },
              codeClasses?.includes('three-col-package')
                ? classes.hideOnSmall3Col
                : classes.hideOnSmall,
              applyContentfulStyles(BindToFields.HighlightTag)
            )}
            component="p"
            variant="overline"
          ></Typography>
        )}
        <CardContent
          className={cx({
            [classes.priceCardContent]: true,
            [classes.borderRadius]: !highlightPackage && !isPricingPagePackage,
            [classes.contentExpanded]:
              variant === PackageVariant.expandedPackage,
            [classes.contentMostPopular]:
              highlightPackage && !isPricingPagePackage,
          })}
        >
          <Box
            className={classes.titleAndPriceContainer} // Add a custom class for additional styling if needed
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body1"
              component="div"
              className={classes.priceCardTitle}
            >
              {title}
            </Typography>

            <Typography variant="h3" className={classes.priceCardSubtitle}>
              {subtitle}
            </Typography>

            <Typography
              variant="body1"
              className={classes.priceCardDescription}
            >
              {insertHandlebarsTemplate(description, handlebarsContext)}
            </Typography>

            <Box className={classes.priceCardPrice} component="div">
              <>
                {formattedAmount ? (
                  <ProductPriceInsert
                    formattedAmount={formattedAmount}
                    formattedDiscount={formattedDiscount}
                    url={purchasePath}
                    linkPriceClass={classes.priceCardPrice}
                    originalPriceClass={classes.priceCardPrice}
                  />
                ) : (
                  <>
                    {priceOverride ? (
                      <Typography className={classes.priceCardPrice}>
                        {priceOverride}
                      </Typography>
                    ) : (
                      <Typography className={classes.priceCardPrice}>
                        {t('purchaseCard.free')}
                      </Typography>
                    )}
                  </>
                )}
              </>

              <Box className={classes.priceCardMonthlyPrice}>
                {formattedAmount &&
                planCode &&
                !brand.name?.includes('TopCV') &&
                amount > MIN_AFFIRM_AMOUNT ? (
                  <AffirmNewTest planCode={planCode} price={amount} />
                ) : brand.isTopCvFr && hasNonNullProperties(alternatePlan) ? (
                  <SplitPaymentLink
                    planCode={alternatePlan.plan_code}
                    intervalTimes={alternatePlan.interval_times}
                    amount={alternatePlan.amount}
                  />
                ) : (
                  <Typography className={classes.oneTimePayment}>
                    {t('purchaseCard.ineligibleForAffirm')}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Typography variant="h3" className={classes.priceCardSubtitleMobile}>
            {insertHandlebarsTemplate(description, handlebarsContext)}
          </Typography>

          <CardActions>{getLinkButton(false)}</CardActions>
        </CardContent>
        <CardContent className={classes.priceFeaturesList}>
          <List className={classes.priceListFeatures}>
            {sellingPoints.map((point, index) => {
              if (isMdOrLarger && index >= 11) return null
              const isDisabled = point.variant === 'disabled'
              const featureIcon = isDisabled ? (
                <CrosDisabled />
              ) : (
                <CheckMarkSVG />
              )
              const textClass = `${classes.priceCardFeature} ${
                isDisabled ? classes.grayFeature : ''
              }`

              return (
                <ListItem
                  key={point.contentful_id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0px',
                    paddingTop: '0px',
                    paddingBottom: '12px',
                    paddingLeft: isDisabled ? '13px' : '16px',
                  }}
                >
                  <ListItemIcon
                    style={{ minWidth: '20px', marginRight: '10px' }}
                  >
                    {featureIcon}
                  </ListItemIcon>
                  <Typography variant="body1" className={textClass}>
                    <RichText
                      rawBody={point.sellingPointBody.raw}
                      references={point.sellingPointBody.references}
                      options={{
                        props: {
                          [BLOCKS.PARAGRAPH]: {
                            variant: 'body2',
                            className: textClass,
                          },
                        },
                      }}
                    />
                  </Typography>
                </ListItem>
              )
            })}
          </List>
        </CardContent>
      </Card>
    </Container>
  )
}

export default PurchaseCard
